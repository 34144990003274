






























































































import { types } from '../../store/types/types.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import GroupsList from '../groups/GroupsList.vue';
import { institutionRole } from '../../../shared/enums/institutionRole';
import UserProfile from '../users/UserProfile.vue';
import { profileService } from '../../../shared/services/api/profile.service';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';

export default {
  components: {
    UserProfile,
    GroupsList,
  },
  data: function () {
    return {
      showGroupList: false,
      showProfileSetting: false,
      institutionRole: institutionRole,
      userProfile: null as InstitutionProfileModel,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      profile: types.GET_CURRENT_PROFILE,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showMobileProfileDropdown: types.GET_SHOW_MOBILE_PROFILE_DROPDOWN,
      isLoadingProfileContext: types.GET_IS_LOADING_PROFILE_CONTEXT,
    }),
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
  },
  mounted() {
    this.getProfileMasterData();
  },
  methods: {
    ...mapMutations({
      hideGroupDropdown: types.MUTATE_HIDE_GROUPS_DROPDOWN,
    }),
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
    }),
    async getProfileMasterData() {
      const profileMasterData = await profileService.getProfileMasterData([this.profile.id]);
      this.userProfile = profileMasterData.institutionProfiles[0];
    },
    groupClick() {
      this.showGroupList = !this.showGroupList;
    },
    handleGroupOpen() {
      this.showGroupList = false;
      this.hideGroupDropdown();
    },
    scrollToTopPage() {
      if (this.showGroupsDropdown) {
        document.querySelector('#aula-header').classList.add('group-dropdown-open');
      } else {
        const transitionTime = 1000;
        setTimeout(() => {
          document.querySelector('#aula-header').classList.remove('group-dropdown-open');
        }, transitionTime);
      }
      document.querySelector('html').scrollTop = 0;
      document.querySelector('body').scrollTop = 0; // this apply for ios device
    },
  },
};
