<template>
  <div class="subscription-header-container">
    <SubscriptionHeaderRecipients
      :recipients="threadParticipants"
      :is-collapsed="isCollapsed"
      :visible-recipients-avatar="visibleParticipantAvatars"
    />

    <SubscriptionMetadata :shown-subscription="shownSubscription" />

    <SubscriptionHeaderActions
      :selected-thread="selectedThread"
      :shown-subscription="shownSubscription"
      :can-show-quick-action="canShowQuickAction"
      :can-show-toggle-sensitive="canHandleToggleSensitive"
      @toggleThreadImportant="markThreadToggle(shownSubscription)"
      @toggleSubscriptionStatus="onChangeSubscriptionStatus"
      @moveToFolderVisibility="changeMoveToFolderVisibility"
      @deleteThread="$refs.deleteModal.show()"
      @toggleMutedStatus="setThreadMutedStatus"
      @toggleMarkThreadSensitiveStatus="$refs.markAsSensitiveModal.show()"
      @toggleUnMarkThreadSensitiveStatus="$refs.unmarkAsSensitiveModal.show()"
      @handleSharingActions="handleSharingActions"
      @leaveThread="$refs.leaveThreadModal.show()"
      @selectMessages="selectMessages(true)"
    />

    <SubscriptionMobileHeaderActions
      :selected-thread="selectedThread"
      :shown-subscription="shownSubscription"
      :can-show-quick-action="canShowQuickAction"
      :can-show-toggle-sensitive="canHandleToggleSensitive"
      @toggleThreadImportant="markThreadToggle(shownSubscription)"
      @toggleSubscriptionStatus="onChangeSubscriptionStatus"
      @moveToFolderVisibility="changeMoveToFolderVisibility"
      @deleteThread="$refs.deleteModal.show()"
      @toggleMutedStatus="setThreadMutedStatus"
      @toggleMarkThreadSensitiveStatus="$refs.markAsSensitiveModal.show()"
      @toggleUnMarkThreadSensitiveStatus="$refs.unmarkAsSensitiveModal.show()"
      @handleSharingActions="handleSharingActions"
      @leaveThread="$refs.leaveThreadModal.show()"
      @selectMessages="selectMessages(true)"
    />

    <MessageMoveToFolderModal
      v-if="showMoveToFolder"
      :chosen-folder-and-mail-owner="chosenFolderAndMailOwner"
      :thread-ids="[shownSubscription.id]"
      :folders="!!chosenFolderAndMailOwner.mailOwnerId ? commonInboxFolders : folders"
      @hide="showMoveToFolder = false"
    />

    <aula-modal
      ref="leaveThreadModal"
      header-text="MESSAGE_LEAVE_MODAL_HEADER"
      ok-text="MESSAGE_LEAVE_MODAL_OK"
      @cancelClicked="$refs.leaveThreadModal.hide()"
      @okClicked="leaveThisThread(shownSubscription)"
    >
      {{ 'MESSAGE_LEAVE_MODAL_BODY_1' | fromTextKey }}<br />{{ 'MESSAGE_LEAVE_MODAL_BODY_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="deleteModal"
      header-text="MESSAGE_CONFIRM_DELETE"
      ok-text="MESSAGE_DELETE_OK"
      data-size="small"
      @cancelClicked="$refs.deleteModal.hide()"
      @okClicked="deleteThisThread"
    >
      {{ 'MESSAGE_DELETE_THREAD_INFO_1' | fromTextKey }}<br />
      {{ deletedThreadWarningMessage }} <br />
      {{ 'MESSAGE_DELETE_THREAD_INFO_3' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="addRecipientsModalVisible"
      css-class="addRecipientsModal"
      header-text="MESSAGE_ADD_RECIPIENTS_HEADER"
      :disable-submit="showAlertNoParticipants || iniviteExistingRecipients.length > 0"
      :is-loading="isLoading"
      @hidden="hideAddRecipientsModal"
      @cancelClicked="hideAddRecipientsModal"
      @okClicked="addRecipients"
    >
      <aula-search-recipients
        :include-otp-from-guardian-in-sub-group="true"
        :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
        :doc-types-api="[docTypes.GROUP, docTypes.PROFILE, docTypes.COMMON_INBOX]"
        :reset-input="resetAddRecipientsInput"
        :existing-participants="existingParticipants"
        :enable-relatives-for-students="true"
        :add-existing-participants="false"
        :allow-create="false"
        :invite-members-when-selecting-group="true"
        :invite-members-when-selecting-group-portal-role="true"
        :invite-group-when-adding-members="true"
        :only-unique-profiles="true"
        :close-dropdown-after-select="false"
        :show-search-icon="true"
        :from-module="moduleTypes.MESSAGES"
        :class="showAlertNoParticipants || iniviteExistingRecipients.length > 0 ? 'red-border' : ''"
        :include-self="chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX"
        :institution-code="
          institutions.find(inst => inst.institutionProfileId == shownSubscription.mailBoxOwner.id) != null
            ? institutions.find(inst => inst.institutionProfileId == shownSubscription.mailBoxOwner.id).institutionCode
            : null
        "
        :scope-employees-to-institution="scopeEmployeesToInstitution"
        :extra-a-p-i-params="extraSearchParam"
        :is-multiple-institution-profile-allowed="false"
        :select-all="false"
        include-choosen-in-search
        class="search-input"
        @emitSelected="setAddMessageRecipient"
      />
      <b-alert variant="danger" :show="showAlertNoParticipants || iniviteExistingRecipients.length > 0">
        <template v-if="iniviteExistingRecipients.length > 0">
          {{ 'MESSAGE_ADD_RECIPIENTS_EXISTED_RECIPIENTS' | fromTextKey }}
          <b>{{ iniviteExistingRecipients.join(', ') }}</b>
        </template>
        <template v-else>
          {{ 'MESSAGE_ADD_RECIPIENTS_NO_RECIPIENTS' | fromTextKey }}
        </template>
      </b-alert>
    </aula-modal>
    <aula-modal
      ref="unmarkAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      :disable-close="true"
      @hidden="hideAddRecipientsModal"
      @closeClicked="cancelMarkSensitive"
      @cancelClicked="cancelMarkSensitive"
      @okClicked="unmarkAsSensitive(shownSubscription.id)"
    >
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE' | fromTextKey }}<br />
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="markAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @hidden="hideAddRecipientsModal"
      @closeClicked="cancelUnmarkSensitive"
      @cancelClicked="cancelUnmarkSensitive"
      @okClicked="markAsSensitive(shownSubscription.id)"
    >
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE' | fromTextKey }}<br />
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="modalInfoBoxForBlockedCommunication"
      header-text="MESSAGE_BLOCK_COMMUNICATION_TITLE"
      :show-cancel="false"
      @okClicked="$refs.modalInfoBoxForBlockedCommunication.hide()"
    >
      {{ 'MESSAGE_BLOCK_COMMUNICATION_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationToAllProfiles"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.blockedCommunicationToAllProfiles.hide()"
      @okClicked="resetBlockedCommunicationModal"
    >
      {{ 'MESSAGE_MODAL_WARNING' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationWarning"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="POST_BUTTON_OK"
      cancel-text="POST_BUTTON_FORTRYD"
      @okClicked="$refs.blockedCommunicationWarning.hide()"
      @cancelClicked="cancelBlockedCommunication"
    >
      {{ 'MESSAGE_MODAL_WARNING_BEGINNING' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (profile.role || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_MIDDLE' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (blockedCommunicationToProfile.profileType || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_END' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="allRecipientsHaveBlockCommunicationChannel"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.allRecipientsHaveBlockCommunicationChannel.hide()"
    >
      {{ 'WARNING_ALL_RECIPIENTS_HAVE_BLOCK_COMMUNICATION_CHANNEL' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.theCommunicationChannelIsBlocked.hide()"
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="
        $refs.theCommunicationChannelIsBlocked.hide();
        removeBlocked();
      "
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS_REMOVE' | fromTextKey }}
      <div class="mt-2">
        <template v-for="(user, index) in blockedUsers">
          {{ user.institutionProfile.name }}
          <span v-if="index + 1 < blockedUsers.length" :key="index">, </span>
        </template>
      </div>
    </aula-modal>
    <aula-modal
      ref="forwardWarningModal"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.forwardWarningModal.hide()"
      @okClicked="
        $refs.forwardWarningModal.hide();
        forwardThread(shownSubscription.id);
      "
    >
      {{ 'WARNING_FORWARD_MESSAGE' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="newRecipientsWarningModal"
      header-text="MESSAGE_WARNING_HEADER"
      @cancelClicked="$refs.newRecipientsWarningModal.hide()"
      @okClicked="
        $refs.newRecipientsWarningModal.hide();
        showAddRecipientsModal();
      "
    >
      {{ 'WARNING_NEW_RECIPIENTS_ADDED' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../store/types/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Vue from 'vue';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';
import { docTypes } from '../../../shared/enums/docTypes';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import { portalRoles } from '../../../shared/enums/portalRoles';
import requestCancelTokenMixin from '../../../shared/mixins/requestCancelTokenMixin';
import { subscriptionStatus } from '../../../shared/enums/subscriptionStatus';
import $ from 'jquery';
import { memberTypes } from '../../../shared/enums/memberTypes';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageUtil } from '../../../shared/utils/messageUtil';
import { MessageFormActionEnum } from './enums/messageFormActionEnum';
import { dateFormatEnum } from '../../../shared/enums/dateFormatEnum';
import { format } from '../../../shared/utils/dateUtil';
import SubscriptionMetadata from './SubscriptionMetadata.vue';
import MessageMoveToFolderModal from './MessageMoveToFolderModal.vue';
import SubscriptionHeaderActions from './SubscriptionHeaderActions.vue';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients.vue';
import SubscriptionHeaderRecipients from './SubscriptionHeaderRecipients.vue';
import SubscriptionMobileHeaderActions from './SubscriptionMobileHeaderActions.vue';
import { messagingService } from '../../../shared/services/api/messaging.service';

export default {
  components: {
    SubscriptionMobileHeaderActions,
    AulaSearchRecipients,
    MessageMoveToFolderModal,
    SubscriptionHeaderActions,
    SubscriptionMetadata,
    SubscriptionHeaderRecipients,
  },
  mixins: [requestCancelTokenMixin],
  inject: {
    getDeletedFolderId: messageProviderKeyEnum.GET_DELETED_FOLDER_ID,
    getCanMoveToDeletedMessageFolder: messageProviderKeyEnum.CAN_MOVE_TO_DELETED_MESSAGE_FOLDER,
    getIsReminderThread: messageProviderKeyEnum.GET_IS_REMINDER_THREAD,
    setMessageFormData: messageProviderKeyEnum.SET_MESSAGE_FORM_DATA,
    setSelectedMessage: messageProviderKeyEnum.SET_SELECTED_MESSAGE,
  },
  props: {
    shownSubscription: Object,
    isCollapsed: { type: Boolean, default: false },
  },
  data: function () {
    return {
      blockedUsers: [],
      portalRoles: portalRoles,
      sensitivityLevel: sensitivityLevel,
      parentTypes: parentTypes,
      messageOwnerTypes: messageOwnerTypes,
      moduleTypes: moduleTypes,
      docTypes: docTypes,
      permissionEnum: permissionEnum,
      showMoveToFolder: false,
      existingRecipients: [],
      iniviteExistingRecipients: [],
      recipients: [],
      recipientsFromGroups: [],
      resetAddRecipientsInput: false,
      blockedCommunicationToProfile: {
        profileType: '',
      },
      blockedCommunicationToAllProfiles: false,
      showAlertNoParticipants: false,
      isLoading: false,
      loadMessagesCancelTokenSource: null,
      selectedThread: null,
      stickThreadMetadata: false,
    };
  },
  computed: {
    dateFormatEnum() {
      return dateFormatEnum;
    },
    ...mapGetters({
      isMobile: types.GET_IS_MOBILE,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
      chosenSubscriptions: types.MESSAGES_GET_CHOSEN_THREAD_IDS,
      commonInboxes: types.MESSAGES_GET_ALL_COMMON_INBOXES,
      commonInboxFolders: types.MESSAGES_GET_FOLDERS_FOR_CURRENT_COMMON_INBOX,
      folders: types.MESSAGES_GET_FOLDERS,
      hasPermission: types.HAS_PERMISSION,
      moreMessagesExist: types.MESSAGES_GET_MORE_MESSAGES_EXITS,
      profile: types.GET_CURRENT_PROFILE,
      institutions: types.GET_INSTITUTIONS,
      blockedCommunicationProfiles: types.GET_BLOCKED_COMMUNICATION_PROFILES,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
      activeChildren: types.GET_ACTIVE_CHILDREN,
      activeInstitutions: types.GET_ACTIVE_INSTITUTIONS,
    }),
    visibleParticipantAvatars() {
      const currentMailBoxOwnerType = this.chosenFolderAndMailOwner.mailOwnerType;
      const currentMailBoxOwnerId =
        currentMailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE
          ? this.profile.profileId
          : this.chosenFolderAndMailOwner.mailOwnerId;

      if (this.isOnlyCurrentUserInThread) {
        return this.threadParticipants;
      }

      if (
        currentMailBoxOwnerType === messageOwnerTypes.OTP_INBOX ||
        currentMailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
      ) {
        return this.threadParticipants.filter(
          p =>
            !(
              p.mailBoxOwner.id === currentMailBoxOwnerId && p.mailBoxOwner.mailBoxOwnerType === currentMailBoxOwnerType
            )
        );
      }

      return this.threadParticipants.filter(p => p.mailBoxOwner.profileId !== this.profile.profileId);
    },
    isOnlyCurrentUserInThread() {
      const isThreadHasOnlyOneParticipant = this.threadParticipants.length === 1;
      let isCurrentUserInTheThread = false;

      if (this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        isCurrentUserInTheThread =
          this.threadParticipants.filter(p => p.mailBoxOwner.profileId === this.profile.profileId).length === 1;
      } else {
        isCurrentUserInTheThread =
          this.threadParticipants.filter(
            p =>
              p.mailBoxOwner.id === this.chosenFolderAndMailOwner.mailOwnerId &&
              p.mailBoxOwner.mailBoxOwnerType === this.chosenFolderAndMailOwner.mailOwnerType
          ).length === 1;
      }

      return isThreadHasOnlyOneParticipant && isCurrentUserInTheThread;
    },
    canShowQuickAction() {
      return !this.isMobile;
    },
    canHandleToggleSensitive() {
      return (
        (this.selectedThread?.sensitive && this.profile.role === portalRoles.EMPLOYEE) ||
        !this.selectedThread?.sensitive
      );
    },
    threadParticipants() {
      return this.shownSubscription.recipients.filter(r => r.leaveTime == null);
    },
    commonInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    otpInboxId() {
      return this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
        ? this.shownSubscription.mailBoxOwner.id
        : null;
    },
    scopeEmployeesToInstitution() {
      return this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX;
    },
    canMoveToDeletedMessageFolder() {
      return this.getCanMoveToDeletedMessageFolder();
    },
    extraSearchParam() {
      const selectedInbox = {
        value: this.shownSubscription.mailBoxOwner.id,
        mailBoxOwnerType: this.shownSubscription.mailBoxOwner.mailBoxOwnerType,
      };
      const groupSearchScope = messageUtil.getGroupSearchScope(selectedInbox, this.commonInboxes);
      return {
        instCode: this.shownSubscription.institutionCode,
        groupSearchScope,
        mailBoxOwnerId: selectedInbox.value,
        mailBoxOwnerType: selectedInbox.mailBoxOwnerType,
      };
    },
    deletedFolderId() {
      return this.getDeletedFolderId();
    },
    deletedThreadWarningMessage() {
      let message = this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2');

      if (this.chosenFolderAndMailOwner?.mailOwnerId) {
        message = this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_2_COMMON');
      }
      if (this.canMoveToDeletedMessageFolder) {
        message += ` ${this.$options.filters.fromTextKey('MESSAGE_DELETE_THREAD_INFO_FROM_NORMAL_FOLDER')}`;
      }
      return message;
    },
    existingParticipants() {
      const existingParticipants = [];
      for (const recipient of this.selectedSubscription.recipients) {
        if (recipient.leaveTime == null) {
          const existingRecipient = {
            id: recipient.mailBoxOwner.id,
            label: recipient.fullName,
            name: recipient.fullName,
            type: 'profile',
            value: recipient.mailBoxOwner.id,
            profileId: recipient.mailBoxOwner.profileId,
          };
          existingParticipants.push(existingRecipient);
        }
      }
      return existingParticipants;
    },
  },
  watch: {
    'selectedSubscription.subscriptionId'(newVal) {
      if (!newVal) {
        this.resetSubscription();
      }
    },
  },
  methods: {
    format,
    ...mapActions({
      leaveThreads: types.MESSAGES_LEAVE_THREADS,
      deleteSubscription: types.DELETE_SUBSCRIPTIONS,
      selectSubscription: types.SELECT_SUBSCRIPTION,
      moveThreadToFolder: types.MOVE_THREADS_TO_FOLDER,
      loadMoreMessages: types.LOAD_MORE_MESSAGES,
      loadMessages: types.SELECT_SUBSCRIPTION,
      switchMarkMessages: types.SWITCH_MARK_SUBSCRIPTIONS,
      addRecipientsToThread: types.ADD_RECIPIENTS_TO_THREAD,
      resetSelectedSubscription: types.RESET_SELECT_SUBSCRIPTION,
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
      adjustSensitivity: types.ACTIONS_ADJUST_SENSITIVITY,
      setBlockedCommunicationProfiles: types.SET_BLOCKED_COMMUNICATION_PROFILES,
      updateSubscriptionStatus: types.MESSAGES_UPDATE_SUBSCRIPTION_STATUS,
      loadNotifications: types.LOAD_NOTIFICATIONS,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setMessagesInSelectMode: types.MUTATE_MESSAGES_IN_SELECT_MODE,
      setSuccessMessage: types.MUTATE_SUCCESS_TEXT,
      mutateDeleteSubscription: types.MUTATE_DELETE_SUBSCRIPTIONS,
      mutateSubscriptionMutedState: types.MUTATE_MESSAGES_MUTE,
    }),
    handleSharingActions(isForward) {
      if (this.shownSubscription.hasSecureDocuments) {
        isForward ? this.$refs.forwardWarningModal.show() : this.$refs.newRecipientsWarningModal.show();
      } else {
        isForward ? this.forwardThread(this.shownSubscription.id) : this.showAddRecipientsModal();
      }
    },
    async setThreadMutedStatus() {
      const payload = {
        muted: !this.shownSubscription.muted,
        subscriptionIds: [this.shownSubscription.subscriptionId],
        commonInboxId:
          this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
            ? this.shownSubscription.mailBoxOwner.id
            : null,
        otpInboxId:
          this.shownSubscription.mailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX
            ? this.shownSubscription.mailBoxOwner.id
            : null,
      };
      await messagingService.setThreadsMuted(payload);

      const toastMsg = payload.muted ? 'SUCCESS_TOAST_MESSAGES_MUTED' : 'SUCCESS_TOAST_MESSAGES_UNMUTED';
      this.setSuccessMessage(toastMsg);

      this.mutateSubscriptionMutedState({
        isMuted: payload.muted,
        subscriptionId: this.shownSubscription.subscriptionId,
      });
    },
    selectMessages(refreshMessages) {
      if (!this.isSteppedUp) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: window.location.href,
          parentType: parentTypes.MESSAGES,
        });
      } else {
        if (refreshMessages) {
          this.cancelPreviousLoadMessagesRequest();
          this.generateLoadMessagesCancelTokenSource();
          this.loadMessages({
            subscriptionId: this.shownSubscription.id,
            mailOwnerId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.COMMON_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
            otpInboxId:
              this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
                ? this.chosenFolderAndMailOwner.mailOwnerId
                : null,
            cancelToken: this.loadMessagesCancelTokenSource.token,
          }).then(() => {
            this.resetLoadMessagesCancelTokenSource();
            if (this.moreMessagesExist) {
              this.selectMessages(false);
            } else {
              this.setMessagesInSelectMode(true);
            }
          });
        } else {
          this.loadMoreMessages({
            scrollElement: this.$el.closest('.subscription-functionalities'),
          }).then(() => {
            if (this.moreMessagesExist) {
              this.selectMessages(false);
            } else {
              this.setMessagesInSelectMode(true);
            }
          });
        }
      }
    },
    async markAsSensitive(subscriptionId) {
      this.$refs.markAsSensitiveModal.hide();
      await this.adjustSensitivity({
        subscriptionId,
        sensitivityLevel: sensitivityLevel.HIGH,
      });
      if (!this.isSteppedUp) {
        this.$router.push({ name: 'messages' });
      }
    },
    unmarkAsSensitive(subscriptionId) {
      this.$refs.unmarkAsSensitiveModal.hide();
      this.adjustSensitivity({
        subscriptionId,
        sensitivityLevel: sensitivityLevel.MEDIUM,
      });
    },
    cancelUnmarkSensitive() {
      this.markedSensitive = false;
      this.$refs.markAsSensitiveModal.hide();
    },
    cancelMarkSensitive() {
      this.markedSensitive = true;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    async leaveThisThread(thread) {
      this.$refs.leaveThreadModal.hide();
      await this.leaveThreads({
        subscriptionIds: [thread.subscriptionId],
      });
      this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_LEAVE_THREAD');
      await this.selectSubscription({
        subscriptionId: thread.id,
        subscriptionLeft: true,
        otpInboxId:
          this.chosenFolderAndMailOwner.mailOwnerType === messageOwnerTypes.OTP_INBOX
            ? this.chosenFolderAndMailOwner.mailOwnerId
            : null,
      });
    },
    async deleteThisThread() {
      this.$refs.deleteModal.hide();
      if (this.canMoveToDeletedMessageFolder) {
        await this.leaveThreads({
          subscriptionIds: [this.shownSubscription.subscriptionId],
        });
        await this.moveThreadToFolder({
          subscriptionIds: [this.shownSubscription.subscriptionId],
          folderId: this.deletedFolderId,
        });
        this.setSuccessMessage('SUCCESS_TOAST_MESSAGES_DELETE_THREADS_FROM_NORMAL_FOLDER');
        this.mutateDeleteSubscription({
          threadIds: [this.shownSubscription.id],
          id: this.profile.id,
        });
      } else {
        this.deleteSubscription({
          threadIds: [this.shownSubscription.id],
        });
      }
      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
      if (this.$route.params.id === this.shownSubscription.id) {
        this.resetSubscription();
      }
    },
    changeMoveToFolderVisibility() {
      this.showMoveToFolder = true;
    },
    removeBlocked() {
      this.recipients = this.recipients.filter(
        profile => !this.blockedUsers.some(user => profile.id === user.institutionProfile.id)
      );
      this.resetAddRecipientsInput = true;
      this.$nextTick(() => {
        this.resetAddRecipientsInput = false;
      });
      if (this.recipients.length !== 0) {
        this.addRecipients();
      }
    },
    markThreadToggle(subscription) {
      this.switchMarkMessages({
        threadIds: [subscription.id],
        isMarked: !subscription.marked,
      });
    },
    async onChangeSubscriptionStatus() {
      if (this.selectedThread) {
        await this.updateSubscriptionStatus({
          subscriptionIds: [this.selectedThread.subscriptionId],
          status: this.selectedThread.read ? subscriptionStatus.UNREAD : subscriptionStatus.READ,
        });
      }

      this.loadNotifications({
        activeChildrenIds: this.activeChildren,
        activeInstitutionCodes: this.activeInstitutions,
      });
    },
    cancelBlockedCommunication() {
      this.$refs.blockedCommunicationWarning.hide();
      this.$refs.addRecipientsModalVisible.hide();
    },
    pushRecipientFromGroup(recipient) {
      if (recipient.type !== memberTypes.GROUP) {
        return;
      }

      const isRecipientFromGroup = this.recipientsFromGroups.some(rec => rec.id === recipient.id);
      if (!isRecipientFromGroup) {
        this.recipientsFromGroups.push(recipient);
      }
    },
    pushRecipient(recipient) {
      if (recipient.type === memberTypes.GROUP) {
        return;
      }

      if (recipient.otpInboxId != null) {
        recipient.mailBoxOwnerType = messageOwnerTypes.OTP_INBOX;
      } else if (recipient.type === docTypes.PROFILE.toLowerCase()) {
        recipient.mailBoxOwnerType = messageOwnerTypes.INSTITUTION_PROFILE;
      } else if (recipient.type === docTypes.COMMON_INBOX.toLowerCase()) {
        recipient.mailBoxOwnerType = messageOwnerTypes.COMMON_INBOX;
      }

      if (this.hasExistingRecipients(recipient) && !recipient.fromGroup) {
        this.iniviteExistingRecipients.push(recipient.label);
      } else {
        this.recipients.push(recipient);
      }
    },
    setAddMessageRecipient(users) {
      this.showAlertNoParticipants = false;
      this.recipients = [];
      this.iniviteExistingRecipients = [];
      this.recipientsFromGroups = [];
      for (const recipient of users) {
        this.pushRecipientFromGroup(recipient);
        this.pushRecipient(recipient);
      }
    },
    hasExistingRecipients(recipient) {
      let hasRecipientBeenAdded = false;
      const recipientPortalRole = recipient.portalRole;
      const recipientMailBoxOwnerType = recipient.role || recipient.mailBoxOwnerType;
      if ([portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE].includes(recipientPortalRole) === true) {
        hasRecipientBeenAdded = this.hasProfileBeenAddedAsRecipient(recipient);
      } else if (recipientMailBoxOwnerType === messageOwnerTypes.OTP_INBOX || recipient.otpInboxId != null) {
        hasRecipientBeenAdded = this.hasOtpInboxBeenAddedAsRecipient(recipient);
      } else if (recipientMailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
        hasRecipientBeenAdded = this.hasCommonInboxBeenAddedAsRecipient(recipient);
      }

      return hasRecipientBeenAdded === true;
    },
    hasProfileBeenAddedAsRecipient(recipient) {
      if (recipient.profileId == null) {
        return false;
      }
      const messageOwnerTypesToSkip = [messageOwnerTypes.COMMON_INBOX, messageOwnerTypes.OTP_INBOX];
      const isProfileInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          messageOwnerTypesToSkip.includes(threadRecipient.mailBoxOwner.mailBoxOwnerType) === true ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.profileId == recipient.profileId;
      });

      const hasAddedRecipientToRecipients = this.recipients.some(
        addedRecipient => addedRecipient.profileId === recipient.profileId
      );
      return isProfileInThread === true || hasAddedRecipientToRecipients === true;
    },
    hasCommonInboxBeenAddedAsRecipient(recipient) {
      if (recipient.id == null) {
        return false;
      }
      const isMessageOwnerInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          threadRecipient.mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.COMMON_INBOX ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.id == recipient.id;
      });

      const hasAddedMessageOwner = this.recipients.some(addedRecipient => addedRecipient.id === recipient.id);
      return isMessageOwnerInThread === true || hasAddedMessageOwner === true;
    },
    hasOtpInboxBeenAddedAsRecipient(recipient) {
      if (recipient.otpInboxId == null) {
        return false;
      }
      const isOtpInThread = this.shownSubscription.recipients.some(threadRecipient => {
        if (
          threadRecipient.mailBoxOwner.mailBoxOwnerType !== messageOwnerTypes.OTP_INBOX ||
          threadRecipient.leaveTime != null
        ) {
          return false;
        }
        return threadRecipient.mailBoxOwner.id == recipient.otpInboxId;
      });

      const hasAddedOtp = this.recipients.some(addedRecipient => addedRecipient.otpInboxId === recipient.otpInboxId);
      return isOtpInThread === true || hasAddedOtp === true;
    },
    hideAddRecipientsModal() {
      this.$refs.addRecipientsModalVisible.hide();
      this.resetAddRecipientsInput = true;
      this.showAlertNoParticipants = false;
      this.iniviteExistingRecipients = [];
      this.$nextTick(() => (this.resetAddRecipientsInput = false));
    },
    addRecipients() {
      // TODO: We're heavily relying on the disable check, functioning in order to pass empty recipients check
      const recipientsToVerifyAsBlocking = this.recipients.filter(recipient => recipient.otpInboxId == null);
      const showWarning = this.recipients.length === 0 && this.recipientsFromGroups.length === 0;
      this.showAlertNoParticipants = showWarning;
      if (showWarning === false) {
        this.isLoading = true;
        this.resolveBlockedUsers({
          creator: this.profile.id,
          profiles: recipientsToVerifyAsBlocking,
        }).then(response => {
          if (response.length > 0) {
            this.blockedUsers = response.filter(
              (value, index, self) =>
                self.map(x => x.institutionProfile.id).indexOf(value.institutionProfile.id) == index
            );
            this.$refs.theCommunicationChannelIsBlocked.show();
            this.isLoading = false;
            return;
          } else {
            this.existingRecipients = this.recipients.filter(recipient =>
              this.shownSubscription.recipients.find(subscription => subscription.address === recipient.aulaEmail)
            );
            this.existingRecipients = this.existingRecipients.concat(this.recipientsFromGroups);
            const mailboxOwnerDtos = this.recipients.map(r => ({
              id: r.mailBoxOwnerType == messageOwnerTypes.OTP_INBOX ? r.otpInboxId : r.id,
              mailBoxOwnerType: r.mailBoxOwnerType,
            }));
            this.addRecipientsToThread({
              recipients: mailboxOwnerDtos,
              subscriptionId: this.shownSubscription.id,
              commonInboxId:
                this.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.COMMON_INBOX
                  ? this.chosenFolderAndMailOwner.mailOwnerId
                  : null,
              otpInboxId:
                this.chosenFolderAndMailOwner.mailOwnerType == messageOwnerTypes.OTP_INBOX
                  ? this.chosenFolderAndMailOwner.mailOwnerId
                  : null,
            })
              .then(() => {
                this.hideAddRecipientsModal();
                this.scrollMessagesToBottom();
                this.isLoading = false;
                this.$emit('recipientsAdded');
              })
              .catch(error => {
                this.hideAddRecipientsModal();
                Vue.nextTick(function () {
                  if (error.status.subCode === 5) {
                    this.$refs.theCommunicationChannelIsBlocked.show();
                  }
                });
                this.isLoading = false;
              });
          }
        });
      }
    },
    showAddRecipientsModal() {
      if (this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        this.$refs.addRecipientsModalVisible.show();
      }
    },
    forwardThread(threadId) {
      if (this.hasPermission(permissionEnum.WRITE_MESSAGE)) {
        this.setSelectedMessage(null);
        this.setMessageFormData({
          threadId,
          commonInboxId: this.commonInboxId,
          otpInboxId: this.otpInboxId,
          action: MessageFormActionEnum.FORWARD,
        });
        this.$router.push({
          name: 'newMessage',
        });
      }
    },
    scrollMessagesToBottom() {
      const el = this.$el.closest('.conversation-holder');
      el.scrollTop = el.scrollHeight;
      if (window.innerWidth < 450) {
        if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
          window.scrollTo(0, el.scrollHeight - 150);
        } else {
          setTimeout(function () {
            $('html').animate({ scrollTop: el.scrollHeight - 150 });
          }, 500);
        }
      }
    },
    resetBlockedCommunicationModal() {
      this.blockedCommunicationToAllProfiles = false;
    },
    cancelPreviousLoadMessagesRequest() {
      this.cancelAxiosRequest(this.loadMessagesCancelTokenSource);
    },
    generateLoadMessagesCancelTokenSource() {
      this.loadMessagesCancelTokenSource = this.getAxiosCancelTokenSource();
    },
    resetLoadMessagesCancelTokenSource() {
      this.loadMessagesCancelTokenSource = null;
    },
    resetSubscription() {
      this.resetSelectedSubscription();
      this.$router.push({ name: 'messages' });
    },
  },
  mounted() {
    this.selectedThread = this.subscriptionsAndBundleThreads.find(
      subscription => +subscription.id === +this.shownSubscription.id
    );
    this.setBlockedCommunicationProfiles().then(() => {
      if (this.blockedCommunicationProfiles.isBlockedAllProfileTypes == true) {
        this.blockedCommunicationToAllProfiles = true;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/elements/modal.scss';
.avatar-group {
  --avatar-size: 40px;
}

.search-input {
  padding-bottom: 20px;
  @include breakpoint-lg() {
    min-width: 400px;
  }
}

.subscription-header-container {
  display: grid;
  grid-template-columns: auto 1fr 124px;
  grid-gap: 24px;

  max-height: 1500px;
  padding: 8px 32px;
  border-bottom: 1px solid var(--color-grey-dark);

  .subscription-metadata {
    min-width: 0;
    height: 100%;
  }

  @include breakpoint-lg-down() {
    grid-template-columns: 1fr;
    background-color: var(--color-grey-dark);
    padding: 0;
    grid-gap: 0;
    position: sticky;
    top: 0;

    .message-recipients-popover {
      height: 60px;
      background-color: var(--color-primary-darker);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      /deep/ .message-recipients-target {
        flex-direction: row;
        justify-content: center;

        .message-recipients-button {
          display: none;
        }

        .avatar-group {
          .avatar {
            --width: 32px;
            --height: 32px;
          }
        }
      }
    }

    /deep/.subscription-metadata {
      padding: 8px 16px;
      .subscription-title {
        min-height: 20px;
        font-size: 16px;
      }

      .subscription-creator {
        font-size: 12px;
      }

      .icon-container {
        .aula-icon {
          --font-size: 20px;
          padding-top: 6px;
        }
      }
    }

    .action-buttons-container {
      display: none;
    }
  }
}

.subscription-functionalities {
  background-color: p $color-grey-light;
  top: 60px;
  @include breakpoint-lg {
    top: 0;
  }
  ul {
    max-width: 450px;
    margin: 0 auto;
    @include breakpoint-lg-down() {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.nav-item {
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    i {
      padding-right: 8px;
    }
  }
  span {
    font-size: 12px;
    color: $color-help-text;
  }
  .dropdown-item {
    span {
      .theme-employee & {
        color: $color-primary-darker-employee;
      }
      .theme-guardian & {
        color: $color-primary-darker-guardian;
      }
      .theme-child & {
        color: $color-primary-darker-child;
      }
      .theme-admin & {
        color: $color-primary-darker-admin;
      }
    }
  }
}

.b-nav-dropdown {
  &.show {
    /deep/ .nav-link,
    /deep/ .nav-link:hover,
    /deep/ .nav-link span,
    /deep/ .nav-link span:hover {
      .theme-guardian & {
        color: $color-primary-base-guardian;
      }
      .theme-child & {
        color: $color-primary-base-child;
      }
      .theme-employee & {
        color: $color-primary-base-employee;
      }
    }
  }
}

.b-nav-dropdown {
  /deep/ a {
    position: relative;
    z-index: 999;
    padding: 0.6rem;
    padding-left: 1.2rem !important;
  }
  .icon-Aula_elipses {
    vertical-align: middle;
  }
  /deep/ .dropdown-menu {
    min-width: 200px;
    padding-top: 3rem;
  }
  /deep/ .dropdown-menu-right {
    z-index: 998;
    width: fit-content;
  }
  .dropdown-item {
    padding-left: 0.5rem;
    i {
      width: 20px;
      display: inline-block;
      text-align: center;
    }
    a:first-child {
      margin-top: 0.75rem;
      padding-left: 1.35rem !important;
    }
  }
  /deep/ > div {
    min-width: 13em;
  }
  &.dropdown-select {
    text-align: left;
  }
}

.modal-content-text {
  .aula-search::after {
    font-family: aula;
    content: '\e913';
    position: absolute;
    right: 60px;
    font-size: 18px;
    top: calc(50% - 3px);
    @include breakpoint-sm-down() {
      right: 50px;
    }
  }
}

.colored {
  color: $color_alert;
}

.mark-important-text {
  display: inline-block;
  width: 45px;
  text-align: left;
}

.mark-read-unread-text {
  display: inline-block;
  min-width: 75px;
  text-align: left;
  @include breakpoint-lg-down() {
    min-width: 40px;
  }
}

a:hover {
  color: initial;
  @include breakpoint-lg() {
    .icon-Aula_star:before {
      color: $color-grey;
    }
    .icon-Aula_star_solid:before {
      color: $color-alert;
    }
  }
}

.theme-guardian {
  a:hover {
    span {
      color: $color-primary-darker-guardian;
    }
  }
}

.theme-employee {
  a:hover {
    span {
      color: $color-primary-darker-employee;
    }
  }
}

.theme-child {
  a:hover {
    span {
      color: $color-primary-darker-guardian;
    }
  }
}
</style>
