




















































































import { defineComponent } from 'vue';
import Avatar from '../../../shared/components/Avatar.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { types } from '../../store/types/types.js';
import AulaButton from '../../../shared/components/AulaButton.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import { parentTypes } from '../../../shared/enums/parentTypes';
import AulaButtons from '../../../shared/components/AulaButtons.vue';
import { Cookie } from '../../../shared/assets/plugins/cookie.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { onboardingStatusEnum } from '../../../shared/enums/onboardingStatusEnum.js';
import ContactInformationUploadAvatarForm from '../contacts/ContactInformationUploadAvatarForm.vue';
import { InstitutionProfileModel } from '../../../shared/models/institutionProfile.model';
import { profileService } from '../../../shared/services/api/profile.service';

export default defineComponent({
  components: { ContactInformationUploadAvatarForm, AulaButtons, Icon, AulaButton, Avatar },
  emits: ['avatarUploaded'],
  data() {
    return {
      profile: null as InstitutionProfileModel,
    };
  },
  computed: {
    ...mapGetters({
      profileContext: types.GET_CURRENT_PROFILE,
      profiles: types.GET_PROFILES_BY_LOGIN,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      delegatedContext: types.GET_DELEGATED_CONTEXT,
      onboardingSteps: types.GET_ONBOARDING_STEPS,
      onboardingStatus: types.IS_ONBOARDING_NEEDED,
    }),
    canEditAvatar() {
      return this.profile?.currentUserCanEditProfilePicture === true;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    nextProfile() {
      return this.profiles.find(profile => profile.portalRole !== this.profileContext.role);
    },
    nextProfileRole() {
      switch (this.nextProfile?.portalRole) {
        case portalRoles.CHILD:
          return this.$t('user.child');
        case portalRoles.EMPLOYEE:
          return this.$t('user.employee');
        case portalRoles.GUARDIAN:
          return this.$t('user.guardian');
        default:
          return '';
      }
    },
    canViewUserData() {
      return this.profileContext.role !== portalRoles.CHILD;
    },
  },
  mounted() {
    this.getProfileMasterData();
  },
  methods: {
    ...mapActions({
      toggleHeaderDropdown: types.TOGGLE_HEADER_DROPDOWN,
      disableImpersonationMode: types.DISABLE_IMPERSONATION_MODE,
      getProfileContext: types.ACTION_GET_PROFILE_CONTEXT,
      checkIsOnboardingNeeded: types.CHECK_IS_ONBOARDING_NEEDED,
    }),
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setDelegatedContext: types.SET_DELEGATED_CONTEXT,
      mutateAdditionalData: types.MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING,
    }),
    async getProfileMasterData() {
      const profileMasterData = await profileService.getProfileMasterData([this.profileContext.id]);
      this.profile = profileMasterData.institutionProfiles[0];
    },
    closeDropdown() {
      this.toggleHeaderDropdown({
        dropdown: 'profile',
        visible: true,
      });
    },
    goToContactInformation() {
      this.$router.push({
        name: 'userview',
        params: { id: this.profileContext.id },
        query: { parent: parentTypes.PROFILE },
      });
      this.closeDropdown();
    },
    goToUserData() {
      if (!this.isSteppedUp) {
        const pathToAdditionalData =
          window.location.origin + '/#' + this.$router.resolve({ name: 'additional-profile-data' }).location.path;
        this.setStepUpNotification({
          showStepUpNotification: true,
          redirectedUrl: pathToAdditionalData,
        });
        return;
      }

      this.$router.push({ name: 'additional-profile-data' });
      this.closeDropdown();
    },
    goToAppHistory() {
      this.$router.push({ name: 'appindstillinger' });
      this.closeDropdown();
    },
    goToNotifications() {
      this.$router.push({ name: 'notifikation' });
      this.closeDropdown();
    },
    goToPrivacyPolicy() {
      this.$router.push({ name: 'privacypolicy' });
      this.closeDropdown();
    },
    showLogoutWarning() {
      this.$refs.logoutWarningModal.show();
      this.closeDropdown();
    },
    resetCookies() {
      Cookie.Erase('isOnboardingStep');
      Cookie.Erase('stepupUserMismatch', '.aula.dk');
    },
    logout() {
      this.disableImpersonationMode();
      this.resetCookies();
      window.location.href = '/auth/logout.php';
    },
    showSwitchProfileWarning() {
      this.$refs.switchProfileWarningModal.show();
      this.closeDropdown();
    },
    async switchProfile() {
      this.$refs.switchProfileWarningModal.hide();
      this.resetCookies();
      if (this.delegatedContext.institutionProfileId != null) {
        await this.setDelegatedContext();
      }
      await this.getProfileContext({
        portalrole: this.nextProfile?.portalRole,
        parent: parentTypes.PORTAL,
      });
      await this.checkIsOnboardingNeeded();

      this.mutateAdditionalData(null);
      if (this.onboardingStatus === onboardingStatusEnum.NEEDS_STEP_UP) {
        this.setStepUpNotification({
          showStepUpNotification: true,
          showStepUpForOnboarding: true,
          redirectedUrl: this.$options.filters.getSiteUrl(),
        });
      }
      if (this.onboardingStatus === onboardingStatusEnum.REQUIRED) {
        Cookie.Create('isOnboardingStep', this.onboardingSteps[0].step);
        this.$router.push({
          name: 'stepOnboarding',
          params: { step: this.onboardingSteps[0].step },
        });
      } else {
        this.$router.push({ name: 'overblik' });
      }
    },
    handleAvatarSaved() {
      this.$refs.uploadAvatarModal.hide();
      this.getProfileContext();
      this.$emit('avatarUploaded');
    },
  },
});
