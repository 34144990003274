<template>
  <div class="gallery-toolbar-container">
    <nav v-if="!isMobile" class="gallery-toolbar navbar navbar-expand-lg" tabindex="-1">
      <ul class="navbar-nav mr-auto">
        <li v-for="(item, index) in multiButtonItems" :key="index" class="nav-item" @click="item.method()">
          <b-btn variant="link" class="tool-link">
            <i class="icon in-circle" :class="item.icon" />
            {{ item.text | fromTextKey }}
          </b-btn>
        </li>
      </ul>
    </nav>
    <multi-button-mobile
      v-else-if="isMultiButtonMobileVisible"
      :items="multiButtonItems"
      @createAlbum="createAlbum()"
      @editAlbum="editAlbum()"
      @deleteAlbum="showDeleteAlbumModal()"
      @activateDownloadAlbums="activateDownloadAlbums()"
      @activateDownloadMedia="activateDownloadMedia()"
      @addMedia="addMedia()"
    />
    <aula-modal
      ref="deleteAlbumModal"
      ok-text="BUTTON_YES"
      @okClicked="deleteAlbum"
      @cancelClicked="$refs.deleteAlbumModal.hide()"
    >
      {{ 'GALLERY_CONFIRM_TO_DELETE_ALBUM_1' | fromTextKey }}
      <strong>{{ 'GALLERY_CONFIRM_TO_DELETE_ALBUM_2' | fromTextKey }}</strong
      ><br />
      {{ 'GALLERY_CONFIRM_TO_DELETE_ALBUM_3' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { types } from '../../store/types/types';
import MultiButtonMobile from '../shared/MultiButtonMobile';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';
import { permissionEnum } from '../../../shared/enums/permissionEnum.ts';

export default {
  props: {
    parent: { type: String, default: parentTypes.PROFILE },
    mediaNumber: { type: Number, default: 0 },
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      isMobile: types.GET_IS_MOBILE,
      isTablet: types.GET_IS_TABLET,
      profile: types.GET_CURRENT_PROFILE,
      userGroup: types.GET_USER_GROUPS,
      activeGroup: types.GET_ACTIVE_GROUP,
      albums: types.GET_ALBUMS,
      institutions: types.GET_INSTITUTIONS,
      hasPermissionGroup: types.HAS_PERMISSION_ON_GROUP,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      hasPermission: types.HAS_PERMISSION,
      limitMedia: types.GET_LIMIT_MEDIA,
      activeAlbum: types.GET_ACTIVE_ALBUM,
      hasNonBlockingProfiles: types.GET_HAS_NON_BLOCKING_PROFILES,
    }),
    isMultiButtonMobileVisible() {
      const hiddenButtonAreas = ['group-create-album', 'group-edit-album', 'group-add-media'];
      return hiddenButtonAreas.includes(this.$route.name) === false;
    },
    multiButtonItems() {
      let multiButtonItems = [];
      const defaultMenuList = [];

      if (this.canCreateAlbum()) {
        defaultMenuList.push({
          icon: this.isMobile ? 'icon-Aula_picture' : 'icon-Aula_plus',
          text: 'GALLERY_TOOLBAR_NEW_ALBUM',
          action: 'createAlbum',
          method: !this.isPreviewMode ? this.createAlbum : null,
        });
      }

      const albumMenu = [
        {
          icon: 'el-icon-download',
          text: 'GALLERY_TOOLBAR_DOWNLOAD_ALBUM',
          action: 'activateDownloadMedia',
          method: !this.isPreviewMode ? this.activateDownloadMedia : null,
        },
      ];

      if (this.activeAlbum) {
        if (this.activeAlbum.currentUserCanEdit) {
          albumMenu.push({
            icon: 'icon-Aula_picture',
            text: 'GALLERY_EDIT_ALBUM',
            action: 'editAlbum',
            method: !this.isPreviewMode ? this.editAlbum : null,
          });
        }
        if (this.activeAlbum.currentUserCanAddMedia && this.limitMedia > this.mediaNumber) {
          albumMenu.push({
            icon: 'icon-Aula_picture',
            text: 'GALLERY_ADD_MEDIA_TO_ALBUM',
            action: 'addMedia',
            method: !this.isPreviewMode ? this.addMedia : null,
            permission: null,
          });
        }
        if (this.activeAlbum.currentUserCanDelete) {
          albumMenu.push({
            icon: 'icon-Aula_bin',
            text: 'GALLERY_DELETE_ALBUM',
            action: 'deleteAlbum',
            method: !this.isPreviewMode ? this.showDeleteAlbumModal : null,
          });
        }
      }
      const galleryMenu = [
        {
          icon: 'el-icon-download',
          text: 'GALLERY_TOOLBAR_DOWNLOAD',
          action: 'activateDownloadAlbums',
          method: !this.isPreviewMode ? this.activateDownloadAlbums : null,
        },
      ];
      if (this.$route.name == 'gallery' || this.$route.name == 'group-gallery') {
        multiButtonItems = defaultMenuList.concat(galleryMenu);
      } else if (this.$route.name == 'myMedias') {
        multiButtonItems = [albumMenu[0]];
      } else {
        multiButtonItems = albumMenu;
      }
      return multiButtonItems;
    },
  },
  methods: {
    ...mapActions({
      getAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
      deleteAlbumById: types.DELETE_ALBUM,
    }),
    canCreateAlbum() {
      const isBlockCommunication = this.profile.communicationBlock && !this.hasNonBlockingProfiles;
      if (isBlockCommunication) {
        return false;
      }
      if (this.parent === parentTypes.GROUP) {
        const activeInstitutionProfile = this.institutions.find(
          institution => institution.institutionCode === this.activeGroup.institutionCode
        );
        return (
          !activeInstitutionProfile.communicationBlock &&
          (this.hasPermissionGroup(permissionEnum.SHARE_MEDIA, this.activeGroup.id) ||
            this.hasPermissionOnInstitution(
              permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS,
              this.activeGroup.institutionCode
            ))
        );
      } else {
        return this.hasPermission(permissionEnum.SHARE_MEDIA) || this.hasPermission(permissionEnum.HANDLE_MEDIA);
      }
    },
    createAlbum() {
      if (this.parent == parentTypes.GROUP) {
        this.$router.push({
          name: 'group-create-album',
          params: { groupId: this.activeGroup.id },
        });
      } else {
        this.$router.push({ name: 'createAlbum' });
      }
    },
    editAlbum() {
      if (this.parent == parentTypes.GROUP) {
        this.$router.push({
          name: 'group-edit-album',
          params: {
            groupId: this.activeGroup.id,
            albumId: this.$route.params.albumId,
          },
        });
      } else {
        this.$router.push({
          name: 'editAlbum',
          params: { albumId: this.$route.params.albumId },
        });
      }
    },
    showDeleteAlbumModal() {
      this.$refs.deleteAlbumModal.show();
    },
    deleteAlbum() {
      this.deleteAlbumById({ albumId: this.$route.params.albumId }).then(() => {
        if (this.parent == parentTypes.GROUP) {
          this.$router.push({
            name: 'group-gallery',
            params: { groupId: this.activeGroup.id },
          });
        } else {
          this.$router.push({ name: 'gallery' });
        }
      });
    },
    activateDownloadAlbums() {
      this.$emit('activateDownloadAlbums');
    },
    addMedia() {
      if (this.parent == parentTypes.GROUP) {
        this.$router.push({
          name: 'group-add-media',
          params: {
            groupId: this.activeGroup.id,
            albumId: this.$route.params.albumId,
          },
        });
      } else {
        this.$router.push({
          name: 'addMedia',
          params: { albumId: this.$route.params.albumId },
        });
      }
    },
    activateDownloadMedia() {
      this.$emit('activateDownloadMedia');
    },
  },
  components: {
    MultiButtonMobile,
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
@import '../../../shared/assets/scss/components/gallery/toolbar.scss';

.navbar-nav {
  display: flex;
  gap: 4px;
}
.tool-link {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 4px;
  height: 38px;
  transition: background-color 150ms ease-in;

  &:hover {
    background-color: var(--color-grey-light);
  }
}
</style>
