<template>
  <div v-if="hasPermissionToWriteMessage" id="message-new" :key="selectedDraft.id || 0">
    <div id="new-message-header" class="new-message-heading">
      <label><Icon class="message-icon" :name="iconClassEnum.CONVERSATION" />{{ newMessageLabel }}</label>
    </div>
    <Overlay center class="loading-overlay" :show="isLoadingRelatedThread">
      <template v-if="newMessage.showSenderInput">
        <label :class="{ 'error.error-text': highlightSender }">{{ 'MESSAGE_LABEL_SENDER' | fromTextKey }}</label>
        <aula-select
          v-model="newMessage.sender"
          :class="{ error: highlightSender }"
          :placeholder="'MESSAGE_PLACEHOLDER_SENDER' | fromTextKey"
        >
          <aula-option v-for="item in senderOptions" :key="item.value" :label="item.text" :value="item.value" />
        </aula-select>
      </template>

      <template v-if="profile.role === portalRoles.OTP">
        <label class="thin">{{ 'MESSAGE_LABEL_FROM' | fromTextKey }} <span class="mandatory">*</span></label>
        <aula-select
          v-model="newMessage.selectedOtpInbox"
          :class="{ 'red-border': !validator.validMessageOtpSender }"
          :disabled="!canSelectInbox"
          :aria-label="'SELECT_OTP_SENDER' | fromTextKey"
          :placeholder="'SELECT_OTP_SENDER' | fromTextKey"
          @change="clearRecipients"
        >
          <aula-option v-for="inbox in otpInboxes" :key="inbox.id" :label="`${inbox.childName}`" :value="inbox.id" />
        </aula-select>
        <b-alert variant="danger" :show="!validator.validMessageOtpSender">
          {{ 'MESSAGE_VALIDATE_OTP_SENDER' | fromTextKey }}
        </b-alert>
      </template>
      <template v-if="isSenderFieldVisible">
        <label class="thin">{{ 'MESSAGE_LABEL_FROM' | fromTextKey }}</label>
        <aula-select
          v-model="newMessage.selectedInbox"
          :disabled="!canSelectInbox"
          :aria-label="'SELECT' | fromTextKey"
          :placeholder="'SELECT' | fromTextKey"
          @change="selectedInboxChanged"
        >
          <el-option-group
            :label="isOptionGroupLabelVisible ? 'MESSAGE_OPTION_GROUP_LABEL_PRIVATE_INBOX' : null | fromTextKey"
          >
            <aula-option
              v-for="institution in filteredInstitutionOptions"
              :id="institution.institutionCode"
              :key="institution.institutionCode"
              :label="
                profile.firstName + ' ' + profile.lastName + (institutions.length > 1 ? ` (${institution.name})` : '')
              "
              :value="{
                mailBoxOwnerType: messageOwnerTypes.INSTITUTION_PROFILE,
                value: institution.institutionCode,
              }"
            />
          </el-option-group>
          <el-option-group
            :label="isOptionGroupLabelVisible ? 'MESSAGE_OPTION_GROUP_LABEL_COMMON_INBOX' : null | fromTextKey"
          >
            <aula-option
              v-for="inbox in commonInboxes"
              :id="inbox.name"
              :key="inbox.name"
              :label="`${inbox.name} (${inbox.institutionName})`"
              :value="{
                mailBoxOwnerType: messageOwnerTypes.COMMON_INBOX,
                value: inbox.id,
              }"
            />
          </el-option-group>
        </aula-select>
      </template>
      <label>{{ 'MESSAGE_LABEL_TO' | fromTextKey }} <span class="mandatory">*</span></label>
      <b-form-input
        v-if="hasRelatedSender"
        class="replied-recipient"
        :value="Vue.filter('displayProfileNameWithMetadata')(newMessage.messageRecipients[0])"
        disabled
      />
      <aula-search-recipients
        v-else
        class="mb-2"
        :class="{
          'red-border': !validator.validMessageRecipientsNotEmpty || !validator.validMessageRecipientLimitForMultiple,
        }"
        :include-otp-from-guardian-in-sub-group="true"
        :existing-participants="messageRecipientSet"
        :reset-input="resetRecipientsInput"
        :close-search-result="resetRecipientsInput"
        :enable-relatives-for-students="true"
        :allow-create="false"
        :close-dropdown-after-select="false"
        :invite-members-when-selecting-group="true"
        :invite-members-when-selecting-group-portal-role="true"
        :invite-group-when-adding-members="true"
        :show-search-icon="true"
        :only-unique-profiles="true"
        :from-module="moduleTypes.MESSAGES"
        :doc-types-api="[docTypes.GROUP, docTypes.PROFILE, docTypes.COMMON_INBOX]"
        :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
        :scope-employees-to-institution="
          newMessage.selectedInbox && newMessage.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
        "
        :institution-code="institutionCodesFromMailBox[0]"
        :include-self="true"
        :extra-a-p-i-params="extraSearchParam"
        allow-all-common-inbox
        :set-focus="setFocus"
        :select-all="false"
        placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
        hover-text="MESSAGE_PLACEHOLDER_RECEIVER"
        @emitSelected="onSelectRecipients"
      />
      <template v-if="hasRelatedSender">
        <label>{{ $t('messages.add_more_recipients') }}</label>
        <aula-search-recipients
          class="mb-2"
          :class="{
            'red-border': !validator.validMessageRecipientLimitForMultiple,
          }"
          :include-otp-from-guardian-in-sub-group="true"
          :existing-participants="existingExtraRecipients"
          :reset-input="resetRecipientsInput"
          :close-search-result="resetRecipientsInput"
          :enable-relatives-for-students="true"
          :allow-create="false"
          :close-dropdown-after-select="false"
          :invite-members-when-selecting-group="true"
          :invite-members-when-selecting-group-portal-role="true"
          :invite-group-when-adding-members="true"
          :show-search-icon="true"
          :only-unique-profiles="true"
          :from-module="moduleTypes.MESSAGES"
          :doc-types-api="[docTypes.GROUP, docTypes.PROFILE, docTypes.COMMON_INBOX]"
          :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
          :scope-employees-to-institution="
            newMessage.selectedInbox && newMessage.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
          "
          :institution-code="institutionCodesFromMailBox[0]"
          :include-self="true"
          :extra-a-p-i-params="extraSearchParam"
          allow-all-common-inbox
          :set-focus="setFocus"
          :select-all="false"
          :hidden-participants="newMessage.messageRecipients"
          placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
          hover-text="MESSAGE_PLACEHOLDER_RECEIVER"
          @emitSelected="handleExtraRecipientsSelected"
        />
      </template>
      <b-alert variant="danger" :show="!validator.validMessageRecipientsNotEmpty">
        {{ isBccFieldOpen ? 'MESSAGE_VALIDATE_RECIPIENTS_WITH_BCC' : 'MESSAGE_VALIDATE_RECIPIENTS' | fromTextKey }}
      </b-alert>
      <b-alert variant="danger" :show="!validator.validMessageRecipientLimitForMultiple">
        {{ 'MESSAGE_VALIDATE_RECIPIENTS_LIMIT' | fromTextKey }}
      </b-alert>
      <template v-if="canAddBccRecipients">
        <div v-if="!isBccFieldOpen">
          <b-button variant="link" @click="onOpenBccClicked">
            <i class="icon-Aula_plus" />
            {{ 'MESSAGE_ADD_BCC_TITLE' | fromTextKey }}
          </b-button>
          <help-box
            id="messages-multiple-threads"
            :title="$options.filters.fromTextKey('MESSAGE_LABEL_BCC')"
            :body="$options.filters.fromTextKey('MESSAGE_ADD_BCC_HELP')"
          />
        </div>
        <template v-else>
          <label>{{ 'MESSAGE_LABEL_BCC' | fromTextKey }}</label>
          <help-box
            id="messages-multiple-threads"
            :title="$options.filters.fromTextKey('MESSAGE_LABEL_BCC')"
            :body="$options.filters.fromTextKey('MESSAGE_ADD_BCC_HELP')"
          />
          <aula-search-recipients
            :class="{ 'red-border': !validator.validBCCMessageRecipientLimitForMultiple }"
            :include-otp-from-guardian-in-sub-group="true"
            :existing-participants="messageBccRecipientSet"
            :reset-input="resetRecipientsInput"
            :close-search-result="resetRecipientsInput"
            :enable-relatives-for-students="true"
            :allow-create="false"
            :close-dropdown-after-select="false"
            :invite-members-when-selecting-group="true"
            :invite-members-when-selecting-group-portal-role="true"
            :invite-group-when-adding-members="true"
            :show-search-icon="true"
            :only-unique-profiles="true"
            :from-module="moduleTypes.MESSAGES"
            :doc-types-api="[docTypes.GROUP, docTypes.PROFILE, docTypes.COMMON_INBOX]"
            :portal-roles="[portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE, portalRoles.OTP]"
            :scope-employees-to-institution="
              newMessage.selectedInbox && newMessage.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
            "
            :institution-code="institutionCodesFromMailBox[0]"
            :include-self="true"
            :extra-a-p-i-params="{
              mailBoxOwnerId,
              mailBoxOwnerType,
              groupSearchScope:
                newMessage.selectedInbox && newMessage.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
                  ? groupTypes.INSTITUTIONAL
                  : null,
            }"
            :set-focus="setFocus"
            :select-all="false"
            allow-all-common-inbox
            placeholder-textkey="ARIA_LABEL_CALENDAR_SEARCH"
            hover-text="MESSAGE_PLACEHOLDER_RECEIVER"
            @emitSelected="onSelectBccRecipients"
          />
          <b-alert variant="danger" :show="!validator.validBCCMessageRecipientLimitForMultiple">
            {{ 'MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_MULTIPLE' | fromTextKey }}
          </b-alert>
        </template>
      </template>
      <label>{{ 'MESSAGE_LABEL_SUBJECT' | fromTextKey }} <span class="mandatory">*</span></label>
      <b-form-input
        v-model="newMessage.messageSubject"
        e2e="message.messageSubject"
        :class="{ 'red-border': !validator.validMessageSubject }"
        :aria-label="'ARIA_LABEL_MESSAGE_SUBJECT' | fromTextKey"
        :placeholder="'MESSAGE_LABEL_SUBJECT_PLACEHOLDER' | fromTextKey"
      />
      <b-alert variant="danger" :show="!validator.validMessageSubject">
        {{ 'MESSAGE_VALIDATE_SUBJECT' | fromTextKey }}
      </b-alert>
      <template v-if="newMessage.replyOrForwardMessage">
        <label>{{ 'MESSAGE_REPLY_LABEL' | fromTextKey }}</label>
        <div class="message message-from-person">
          <div class="message-body" v-html="newMessage.replyOrForwardMessage.text.html" />
        </div>
      </template>
      <b-row class="margin-top">
        <b-col>
          <label>{{ 'MESSAGE_CONTENT' | fromTextKey }} <span class="mandatory">*</span></label>
          <PluginTextEditor
            v-if="editorReady"
            id="message-textarea"
            ref="message-textarea"
            v-model="newMessage.messageText"
            class="scrollbar mb-2"
            :max-length="maxLength"
            :class="{
              'red-border': !validator.validMessageText || !validator.validMessageTextLength,
            }"
            :placeholder-textkey="'MESSAGE_TYPING_PLACEHOLDER'"
            :aria-label="'ARIA_LABEL_WRITE_MESASGE'"
            :institution-code="newMessage.institutionCode"
            @documentAddedToEditor="markAsSensitive()"
            @textTooLong="disableButton"
          />
          <b-alert variant="danger" :show="!validator.validMessageText">
            {{ 'MESSAGE_VALIDATE_TEXT' | fromTextKey }}
          </b-alert>
          <b-alert variant="danger" :show="!validator.validMessageTextLength">
            {{ 'POST_CONTENT_TOO_LONG' | fromTextKey }}
          </b-alert>
        </b-col>
      </b-row>
      <div class="message-utility-container">
        <upload
          ref="uploaderFiles"
          :upload-from-filesharing="canAttachFromSecureFiles"
          :limit-file-upload="200"
          :allow-media="hasPermissionToAttachMedia"
          :existing-media="newMessage.existingAttachments"
          :upload-from-gallery="profile.role !== portalRoles.OTP"
          :show-attach-from-google-drive="canAttachGoogleDriveFile"
          :show-attach-from-one-drive="canAttachOneDriveFile"
          @attachSecureDocuments="attachSecureDocuments"
          @mediaUpdated="filesChanged"
        />
        <div class="d-flex align-items-center">
          <div v-show="canToggleSensitivityLevel && !isLoadingRelatedThread" class="sensitive-msg-container">
            <b-form-checkbox
              v-model="newMessage.isSensitive"
              class="d-flex align-items-center"
              :value="true"
              :unchecked-value="false"
              :disabled="newMessage.hasSecureDocuments"
              @change="confirmMarkSensitive"
            >
              {{ markMessageAsSensitiveText }}
              <Icon :name="iconClassEnum.LOCK" class="ml-1" />
            </b-form-checkbox>
            <help-box
              id="sensitive-mesasge-tip"
              class="ml-2"
              title=""
              :align-right="true"
              :body="$options.filters.fromTextKey('MESSAGE_DROPDOWN_SENSITIVE_HELP')"
            />
          </div>
          <div v-if="isVisibleSensitiveIndicator" class="sensitive-msg-container">
            <strong>{{ markedAsSensitiveText }}</strong>
            <Icon :name="iconClassEnum.LOCK" class="ml-1" />
          </div>
        </div>
      </div>
    </Overlay>
    <aula-form-footer
      class="mt-0"
      :is-loading="isLoading"
      :show-line="false"
      :show-cancel-mobile="true"
      :button-shape-mobile="'square'"
      :submit-disabled="submitDisabled || isSearchComponentLoading"
      submit-text="MESSAGE_SEND_BUTTON"
      @cancelClicked="cancel"
      @submitClicked="handleSendClicked"
    />
    <aula-modal
      ref="autoReplyModal"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="MESSAGE_WARNING_SEND"
      @cancelClicked="$refs.autoReplyModal.hide()"
      @okClicked="acceptWarning"
    >
      {{ 'MESSAGE_WARNING_BODY' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="tooManyRecipientsModal"
      header-text="MESSAGE_WARNING_HEADER"
      :disable-close="true"
      :show-cancel="false"
      @okClicked="$refs.tooManyRecipientsModal.hide()"
    >
      {{ showTooManyRecipientsWarning }}
    </aula-modal>
    <aula-modal
      ref="tooManyRecipientsWithPermissionModal"
      header-text="MESSAGE_WARNING_HEADER"
      :disable-close="true"
      @okClicked="okClickedWhenTooManyRecipients()"
      @cancelClicked="$refs.tooManyRecipientsWithPermissionModal.hide()"
    >
      {{ showTooManyRecipientsWithPermissionWarning }}
    </aula-modal>
    <aula-modal
      ref="cancelMessageModal"
      header-text="MESSAGE_CANCEL_HEADER"
      ok-text="MESSAGE_BUTTON_OK"
      @cancelClicked="$refs.cancelMessageModal.hide()"
      @okClicked="
        $refs.cancelMessageModal.hide();
        discardMessage();
      "
    >
      {{ 'MESSAGE_DELETE_THREAD_INFO_1' | fromTextKey }}<br />
      {{ 'MESSAGE_DELETE_THREAD_INFO_3' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="markAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @closeClicked="cancelUnmarkSensitive"
      @cancelClicked="cancelUnmarkSensitive"
      @okClicked="markAsSensitive()"
    >
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE' | fromTextKey }}<br />
      {{ 'WARNING_STEPUP_MARK_MESSAGE_AS_SENSITIVE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="unmarkAsSensitiveModal"
      ok-text="YES"
      header-text="MESSAGE_WARNING_HEADER"
      @closeClicked="cancelMarkSensitive"
      @cancelClicked="cancelMarkSensitive"
      @okClicked="unmarkAsSensitive()"
    >
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE' | fromTextKey }}<br />
      {{ 'UNMARK_AS_SENSITIVE_MESSAGE_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="blockedCommunicationToProfile"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="POST_BUTTON_OK"
      cancel-text="POST_BUTTON_FORTRYD"
      @cancelClicked="cancel"
      @okClicked="$refs.blockedCommunicationToProfile.hide()"
    >
      {{ 'MESSAGE_MODAL_WARNING_BEGINNING' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (profile.role || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_PROFILEMIDDLE' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (blockedCommunicationToProfile.profileType || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_PROFILEEND' | fromTextKey }}
    </aula-modal>

    <aula-modal
      ref="blockedCommunicationToAllProfilesOnGroup"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="POST_BUTTON_OK"
      cancel-text="POST_BUTTON_FORTRYD"
      @cancelClicked="cancel"
      @okClicked="$refs.blockedCommunicationToAllProfilesOnGroup.hide()"
    >
      {{ 'MESSAGE_MODAL_WARNING_BEGINNING' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (profile.role || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_MIDDLE' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (blockedCommunicationToProfile.profileType || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_END' | fromTextKey }}
    </aula-modal>

    <aula-modal
      ref="blockedCommunicationToProfilesOnGroup"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="POST_BUTTON_OK"
      cancel-text="POST_BUTTON_FORTRYD"
      @cancelClicked="cancel"
      @okClicked="$refs.blockedCommunicationToProfilesOnGroup.hide()"
    >
      {{ 'MESSAGE_MODAL_WARNING_BEGINNING' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (profile.role || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_MIDDLE' | fromTextKey }}
      {{ ('MESSAGE_PROFILE_' + (blockedCommunicationToProfile.profileType || '').toUpperCase()) | fromTextKey }}
      {{ 'MESSAGE_MODAL_WARNING_END' | fromTextKey }}
    </aula-modal>

    <aula-modal
      ref="allRecipientsHaveBlockCommunicationChannel"
      header-text="MESSAGE_WARNING_HEADER"
      :show-cancel="false"
      @okClicked="$refs.allRecipientsHaveBlockCommunicationChannel.hide()"
    >
      {{ 'WARNING_ALL_RECIPIENTS_HAVE_BLOCK_COMMUNICATION_CHANNEL' | fromTextKey }}
    </aula-modal>

    <aula-modal
      ref="theCommunicationChannelIsBlocked"
      header-text="MESSAGE_WARNING_HEADER"
      ok-text="BUTTON_YES"
      cancel-text="NO"
      @cancelClicked="$refs.theCommunicationChannelIsBlocked.hide()"
      @okClicked="removeBlockedConfirmed()"
    >
      {{ 'WARNING_THE_COMMUNICATION_CHANNEL_IS_BLOCKED_USERS' | fromTextKey }}
      <div class="mt-2">
        {{
          newMessage.blockedUsers.map(u => (u.otpInbox ? u.otpInbox.displayName : u.institutionProfile.name)).join(', ')
        }}
      </div>
    </aula-modal>
    <aula-modal
      ref="shareableSecureFileModal"
      header-text="MESSAGE_FAIL_HEADER"
      :show-cancel="false"
      @okClicked="$refs.shareableSecureFileModal.hide()"
    >
      {{ 'MESSAGE_SHAREABLE_SECURE_FILES' | fromTextKey }}
    </aula-modal>
    <toastr ref="externalRecipientsToastr" variant="warning" icon-font-size="1em" circle-background="white">
      {{ 'MESSAGE_ADD_RECIPIENTS_EXTERNAL' | fromTextKey }}
    </toastr>
  </div>
</template>

<script>
import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
import upload from '../../../shared/components/Upload.vue';
import { types } from '../../store/types/types';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { docTypes } from '../../../shared/enums/docTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import AulaSearchRecipients from '../../../shared/components/AulaSearchRecipients';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { messageOwnerTypes } from '../../../shared/enums/messageOwnerTypes';
import { sensitivityLevel } from '../../../shared/enums/sensitivityLevel';
import { groupTypes } from '../../../shared/enums/groupTypes';
import $ from 'jquery';
import { NewMessage } from '../../business/newMessage';
import { messageProviderKeyEnum } from '../../../shared/enums/messageProviderKeyEnum';
import { messageUtil } from '../../../shared/utils/messageUtil';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import PluginTextEditor from '../text_editor/PluginTextEditor.vue';
import { messageTypes } from '../../../shared/enums/messageTypes';
import { MessageFormActionEnum } from './enums/messageFormActionEnum.ts';
import { messagingService } from '../../../shared/services/api/messaging.service';
import Overlay from '../../../shared/components/Overlay.vue';

export default {
  components: {
    Overlay,
    PluginTextEditor,
    Icon,
    AulaSearchRecipients,
    upload,
  },
  inject: {
    getSearchModel: messageProviderKeyEnum.SEARCH_MODEL,
    getSelectedMessage: messageProviderKeyEnum.GET_SELECTED_MESSAGE,
    setMessageFormData: messageProviderKeyEnum.SET_MESSAGE_FORM_DATA,
    getMessageFormData: messageProviderKeyEnum.GET_MESSAGE_FORM_DATA,
    setSelectedMessage: messageProviderKeyEnum.SET_SELECTED_MESSAGE,
  },
  data: function () {
    return {
      Vue: Vue,
      sensitivityLevel: sensitivityLevel,
      groupTypes: groupTypes,
      editorReady: false,
      resetRecipientsInput: false,
      messageRecipientSet: [],
      existingExtraRecipients: [],
      inSave: null,
      isLoading: false,
      isLoadingRelatedThread: false,
      submitDisabled: false,
      blockedCommunicationToProfile: {
        profileType: '',
      },
      blockedCommunicationToProfilesOnGroup: {
        profileTypes: [],
      },
      blockedCommunicationToAllProfilesOnGroup: {
        profileTypes: [],
      },
      showTooManyRecipientsWarning: '',
      showTooManyRecipientsWithPermissionWarning: '',
      docTypes: docTypes,
      moduleTypes: moduleTypes,
      portalRoles: portalRoles,
      messageOwnerTypes: messageOwnerTypes,
      //  it is not set anywhere else
      setFocus: false,
      maxLength: 10000,
      newMessage: new NewMessage(),
      messageBccRecipientSet: [],
      isBccFieldOpen: false,
      canToggleSensitivityLevel: true,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    ...mapGetters({
      chosenFolderAndMailOwner: types.MESSAGES_GET_CHOSEN_FOLDER_AND_MAIL_OWNER,
      profile: types.GET_CURRENT_PROFILE,
      autoreply: types.MESSAGES_GET_AUTOREPLY,
      commonInboxes: types.MESSAGES_GET_ALL_COMMON_INBOXES,
      selectedDraft: types.MESSAGES_GET_SELECTED_DRAFT,
      mailBoxOwners: types.MESSAGES_GET_MAIL_BOX_OWNERS,
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
      children: types.GET_CHILDREN,
      activeChildIds: types.GET_ACTIVE_CHILDREN,
      messages: types.MESSAGES_GET,
      subscriptions: types.MESSAGES_GET_SUBSCRIPTIONS,
      subscriptionsAndBundleThreads: types.MESSAGES_GET_SUBSCRIPTIONS_AND_BUNDLE_THREADS,
      hasPermission: types.HAS_PERMISSION,
      hasPermissionOnInstitution: types.HAS_PERMISSION_ON_INSTITUTION,
      blockedCommunicationProfiles: types.GET_BLOCKED_COMMUNICATION_PROFILES,
      oldDraftThreadId: types.MESSAGES_GET_OLD_DRAFT_THREAD_ID,
      prefilledNewEmailContact: types.MESSAGES_GET_PREFILLED_NEW_EMAIL_CONTACT,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      sameAuthorityInstitutions: types.GET_SAME_AUTHORITY_INSTITUTIONS,
      isMobile: types.GET_IS_MOBILE,
      isSearchComponentLoading: types.IS_SEARCH_COMPONENT_LOADING,
      selectedRecipients: types.MESSAGES_GET_SELECTED_RECIPIENTS,
      otpInboxes: types.MESSAGES_GET_OTP_INBOXES,
      masterData: types.GET_SELECTED_PROFILE,
      selectedSubscription: types.MESSAGES_GET_SELECTED_SUBSCRIPTION,
    }),
    filteredInstitutionOptions() {
      return this.institutions.filter(institution => !institution.communicationBlock);
    },
    markedAsSensitiveText() {
      if (this.isMobile) {
        return this.$options.filters.fromTextKey('MESSAGE_CREATE_SENSITIVE_MOBILE');
      }
      return this.$options.filters.fromTextKey('MESSAGE_MARKED_AS_SENSITIVE');
    },
    hasRelatedSender() {
      return (
        [MessageFormActionEnum.ANSWER_DIRECTLY, MessageFormActionEnum.ANSWER_THREAD_CREATOR].includes(
          this.newMessage.action
        ) && this.newMessage.messageRecipients[0]
      );
    },
    canSelectInbox() {
      return this.newMessage.action === MessageFormActionEnum.CREATE;
    },
    messageFormData() {
      return this.getMessageFormData();
    },
    isVisibleSensitiveIndicator() {
      return !this.canToggleSensitivityLevel && this.newMessage.isSensitive;
    },
    newMessageLabel() {
      switch (this.newMessage.action) {
        case MessageFormActionEnum.FORWARD:
          return this.$options.filters.fromTextKey('MESSAGE_NEW_TITLE_FORWARD_THREAD');
        case MessageFormActionEnum.ANSWER_DIRECTLY:
          return this.$options.filters.fromTextKey('MESSAGE_NEW_TITLE_ANSWER_DIRECTLY');
        case MessageFormActionEnum.ANSWER_THREAD_CREATOR:
          return this.$options.filters.fromTextKey('MESSAGE_START_NEW_THREAD');
        default:
          return this.$options.filters.fromTextKey('MESSAGE_NEW_TITLE');
      }
    },
    extraSearchParam() {
      return {
        mailBoxOwnerId: this.mailBoxOwnerId,
        mailBoxOwnerType: this.mailBoxOwnerType,
        groupSearchScope: messageUtil.getGroupSearchScope(this.newMessage.selectedInbox, this.commonInboxes),
      };
    },
    mailBoxOwnerId() {
      const selectedInbox = this.newMessage.selectedInbox;
      const institutions = this.institutions;
      if (selectedInbox == null) {
        return null;
      }
      const institution = institutions.find(institution => institution.institutionCode === selectedInbox.value);
      let mailBoxOwnerId = selectedInbox.value;
      if (selectedInbox.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE) {
        mailBoxOwnerId = institution.mailboxId;
      }
      return mailBoxOwnerId;
    },
    mailBoxOwnerType() {
      return this.newMessage.selectedInbox?.mailBoxOwnerType;
    },
    markMessageAsSensitiveText() {
      return this.isMobile
        ? Vue.filter('fromTextKey')('MESSAGE_CREATE_SENSITIVE_MOBILE')
        : Vue.filter('fromTextKey')('MESSAGE_CREATE_SENSITIVE');
    },
    isSenderFieldVisible() {
      return (
        this.filteredInstitutionOptions.length + this.commonInboxes.length > 1 && this.profile.role !== portalRoles.OTP
      );
    },
    isOptionGroupLabelVisible() {
      return this.institutions.length > 0 && this.commonInboxes.length > 0;
    },
    canAttachFromSecureFiles() {
      return (
        this.profile.role === portalRoles.EMPLOYEE &&
        (this.hasPermission(permissionEnum.HANDLE_SECURE_FILES) ||
          this.hasPermission(permissionEnum.HANDLE_SECURE_FILES_LIMITED))
      );
    },
    senderOptions() {
      return this.mailBoxOwners.reduce(
        (options, mailBoxOwner) => [
          ...options,
          {
            value: mailBoxOwner.address,
            text: Vue.filter('displayProfileNameWithMetadata')(mailBoxOwner),
          },
        ],
        []
      );
    },
    highlightSender() {
      return this.newMessage.showSenderInput && !this.newMessage.sender;
    },
    isForwardMessage() {
      return this.newMessage.action === MessageFormActionEnum.ANSWER_DIRECTLY;
    },
    canAddBccRecipients() {
      return this.profile.role === portalRoles.EMPLOYEE && !this.hasRelatedSender;
    },
    canAttachGoogleDriveFile() {
      return this.institutionCodesFromMailBox.some(
        institutionCode =>
          this.hasPermissionOnInstitution(permissionEnum.ATTACH_GOOGLE_DRIVE_FILE, institutionCode) ||
          this.hasPermissionOnInstitution(permissionEnum.IMPORT_MEDIA_FROM_GOOGLE_DRIVE, institutionCode)
      );
    },
    canAttachOneDriveFile() {
      return this.institutionCodesFromMailBox.some(
        institutionCode =>
          this.hasPermissionOnInstitution(permissionEnum.ATTACH_ONEDRIVE_FILE, institutionCode) ||
          this.hasPermissionOnInstitution(permissionEnum.IMPORT_MEDIA_FROM_ONEDRIVE, institutionCode)
      );
    },
    hasPermissionToWriteMessage() {
      return this.hasPermission(permissionEnum.WRITE_MESSAGE);
    },
    hasPermissionToAttachMedia() {
      return this.hasPermission(permissionEnum.HANDLE_MEDIA);
    },
    hasPermissionToUseGroupsAsDistributionLists() {
      return this.hasPermission(permissionEnum.USE_GROUPS_AS_DISTRIBUTION_LISTS);
    },
    validator() {
      return this.newMessage.validator;
    },
    searchModel() {
      return this.getSearchModel();
    },
    selectedMessage() {
      return this.getSelectedMessage();
    },
    institutionCodesFromMailBox() {
      return this.newMessage.getInboxInstitutionCodes(this.commonInboxes, this.otpInboxes, this.institutions);
    },
    subscriptionIdFromPath() {
      return parseInt(this.$route.params.subscriptionId);
    },
    messageIdFromPath() {
      return this.$route.params.messageId;
    },
    chosenSubscription() {
      return this.subscriptionsAndBundleThreads.find(s => s.id == this.subscriptionIdFromPath);
    },
    chosenMessage() {
      return this.selectedMessage;
    },
  },
  watch: {
    profile: function () {
      this.newMessage.afterProfileChange(this.profile.role);
    },
    'newMessage.institutionCode'() {
      this.newMessage.setSelectedInboxForInstitutionProfile();
    },
    institutions() {
      this.newMessage.setInstitutionCodeByPrimaryInstitution(this.institutions);

      if (this.institutions.length > 0) {
        // todo: REVIEW - what it does ?
        this.getSameAuthorityInstitutions(this.institutions);
      }
    },
    chosenFolderAndMailOwner: {
      handler() {
        // todo: REVIEW: it looks like it does not have sense ?
        let isDraft = this.oldDraftThreadId || this.selectedDraft.id;
        if (!isDraft && localStorage.getItem('tempDraftThread') != '') {
          isDraft = true;
        }
      },
      deep: true,
    },
    'newMessage.selectedInbox': {
      handler(newValue, oldValue) {
        if (
          !this.isSelectedInboxEqual(newValue, oldValue) &&
          !(
            newValue?.mailBoxOwnerType === oldValue?.mailBoxOwnerType &&
            newValue?.mailBoxOwnerType === messageOwnerTypes.INSTITUTION_PROFILE
          )
        ) {
          this.deleteDraftThread({
            id: this.profile.id,
            key: localStorage.getItem('tempDraftThread'),
            selectedInbox: oldValue,
          });
        }
      },
      deep: true,
    },
    'newMessage.selectedOtpInbox'(value) {
      this.newMessage.setSelectedInboxToOtp(value);
    },
    'newMessage.messageText': function () {
      this.validator.validateMessageText();
    },
    'newMessage.messageSubject': function () {
      this.validator.validateMessageSubject();
    },
  },
  created() {
    $('.conversation-holder').animate({ scrollTop: 0 });
  },
  mounted() {
    localStorage.setItem('tempDraftThread', '');

    this.initMessageForm();
  },
  updated() {
    setTimeout(() => {
      if (this.editorReady) {
        this.saveDraft();
      }
    }, 500);
  },
  beforeDestroy() {
    this.editorReady = false;
    this.setSelectedRecipients([]);
    this.resetSelectedDraftThread();
    this.setMessageFormData(null);
    this.setSelectedMessage(null);
  },
  methods: {
    ...mapActions({
      startNewThread: types.START_NEW_THREAD,
      addSubscription: types.ADD_SUBSCRIPTION,
      draftThread: types.MESSAGES_GET_DRAFT_THREAD,
      saveDraftThread: types.MESSAGES_SAVE_DRAFT_THREAD,
      setSelectableMailBoxOwners: types.SET_SELECTABLE_MAIL_BOX_OWNERS,
      setBlockedCommunicationProfiles: types.SET_BLOCKED_COMMUNICATION_PROFILES,
      getSameAuthorityInstitutions: types.ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS,
      createAttachments: types.CREATE_ATTACHMENTS,
      updateAttachments: types.UPDATE_ATTACHMENTS,
      resolveBlockedUsers: types.ACTION_GET_BLOCKED_COMMUNICATION,
      createDocumentLinks: types.CREATE_DOCUMENT_LINKS,
      getProfileMasterData: types.SELECT_PROFILE,
    }),
    ...mapMutations({
      deleteDraftThread: types.MUTATE_DELETE_DRAFT_THREAD,
      resetSelectedDraftThread: types.MUTATE_RESET_SELECTED_DRAFT,
      resetPrefilledNewEmailContact: types.MUTATE_RESET_PREFILLED_NEW_EMAIL_CONTACT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
      setMessageSearchQuery: types.MUTATE_SUBSCRIPTIONS_SEARCH_QUERY,
      setSelectedRecipients: types.MUTATE_SELECTED_RECIPIENTS,
    }),
    isSelectedInboxEqual(newInbox, oldInbox) {
      return newInbox.mailBoxOwnerType === oldInbox?.mailBoxOwnerType && newInbox.value === oldInbox?.value;
    },
    prefillEmailContact() {
      if (this.prefilledNewEmailContact) {
        this.newMessage.setRecipientFromPrefilledNewEmailContact(this.prefilledNewEmailContact);
        this.messageRecipientSet = this.newMessage.messageRecipients;
        this.resetPrefilledNewEmailContact();
      }
    },
    setReplyOrForwardMessage(message) {
      const chosenMessage = message;
      const chosenSubscription = this.selectedSubscription;

      if (chosenMessage.messageType === messageTypes.MESSAGE_DELETED) {
        chosenMessage.sender = {
          ...chosenSubscription.threadCreator,
          id: chosenSubscription.threadCreator.mailBoxOwner.id,
        };
        chosenMessage.text = {
          html: this.$options.filters.fromTextKey('MESSAGE_IS_DELETED'),
        };
      }

      this.newMessage.replyOrForwardMessage = chosenMessage;
      if (this.newMessage.messageRecipients.length === 0) {
        const recipient = messageUtil.mapInstitutionProfileToRecipient(chosenMessage.sender);
        this.newMessage.updateRecipients([recipient]);
      }
    },
    populateMessageFormData() {
      this.newMessage.subscriptionId = this.subscriptionIdFromPath || this.selectedDraft.subscriptionId;
      this.newMessage.messageId = this.messageIdFromPath || this.selectedDraft.messageId;
      this.newMessage.profileRole = this.profile.role;
      this.editorReady = true;
      this.setFocus = true;

      if (this.messageFormData) {
        this.newMessage.institutionCode =
          this.messageFormData.institutionCode || this.selectedSubscription.institutionCode;
        this.newMessage.action = this.messageFormData.action;
        this.newMessage.relatedThreadId = this.messageFormData.threadId;
      }

      if (this.selectedMessage) {
        this.setReplyOrForwardMessage(this.selectedMessage);
      }
    },
    async populateRelatedThreadInfo() {
      if (!this.newMessage.action || this.newMessage.action === MessageFormActionEnum.CREATE) {
        return;
      }

      const threadId = this.messageFormData?.threadId || this.selectedDraft.relatedThreadId;
      const commonInboxId =
        this.newMessage.selectedInbox.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX
          ? this.newMessage.selectedInbox.value
          : null;
      const otpInboxId = this.messageFormData?.otpInboxId || null;
      this.newMessage.selectedOtpInbox = otpInboxId;
      const payload = {
        threadId,
        commonInboxId,
        otpInboxId,
      };
      this.isLoadingRelatedThread = true;
      const threadInfoLight = await messagingService.getThreadInfoLight(payload);
      this.isLoadingRelatedThread = false;
      this.canToggleSensitivityLevel = !threadInfoLight.isSensitive || this.profile.role === portalRoles.EMPLOYEE;
      this.newMessage.populateMessageFormData(threadInfoLight);
      if (this.newMessage.action === MessageFormActionEnum.ANSWER_THREAD_CREATOR) {
        this.setReplyOrForwardMessage(threadInfoLight.firstMessage);
      }
      if (threadInfoLight.isSensitive && this.profile.role !== portalRoles.EMPLOYEE) {
        this.newMessage.isSensitive = true;
      }
    },
    initMessageForm() {
      this.$nextTick(() => {
        this.populateMessageFormData();
        this.mapSelectedDraft();
        this.prefillEmailContact();
        this.initSelectedInbox();
        this.initRecipients();
        this.populateRelatedThreadInfo();
        this.setBlockedCommunicationProfiles();
      });
    },
    async initRecipients() {
      if (!this.$route.query.profile) {
        return;
      }
      const payload = {
        instProfileIds: [this.$route.query.profile],
      };
      this.submitDisabled = true;
      await this.getProfileMasterData(payload);
      const recipients = this.masterData.institutionProfiles.map(profile =>
        messageUtil.mapInstitutionProfileToRecipient(profile)
      );
      this.newMessage.updateRecipients(recipients);
      this.messageRecipientSet = recipients;
      this.setSelectedRecipients(this.newMessage.allRecipients);
      this.submitDisabled = false;
    },
    resetAdvancedSearch() {
      this.searchModel.resetSearchParams();
      this.searchModel.setFilter(this.chosenFolderAndMailOwner.filter);
    },
    onOpenBccClicked() {
      this.isBccFieldOpen = true;
    },
    clearRecipients() {
      this.resetRecipientsInput = !this.resetRecipientsInput;
      this.newMessage.clearMessageRecipients();
      this.newMessage.clearBccMessageRecipients();
      this.messageRecipientSet = [];
      this.messageBccRecipientSet = [];
    },
    attachSecureDocuments(documents) {
      this.markAsSensitive();
      this.$refs.uploaderFiles.addNewMedia(documents);
    },
    confirmMarkSensitive() {
      if (this.newMessage.isSensitive) {
        this.$refs.markAsSensitiveModal.show();
      } else {
        this.$refs.unmarkAsSensitiveModal.show();
      }
    },
    markAsSensitive() {
      this.newMessage.isSensitive = true;
      this.$refs.markAsSensitiveModal.hide();
    },
    unmarkAsSensitive() {
      this.newMessage.isSensitive = false;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    cancelUnmarkSensitive() {
      this.newMessage.isSensitive = false;
      this.$refs.markAsSensitiveModal.hide();
    },
    cancelMarkSensitive() {
      this.newMessage.isSensitive = true;
      this.$refs.unmarkAsSensitiveModal.hide();
    },
    disableButton(state) {
      this.submitDisabled = state;
    },
    async handleSendClicked() {
      this.isLoading = true;
      if (this.newMessage.isMessageContentSensitive) {
        this.markAsSensitive();
      }

      if (this.newMessage.isValid()) {
        this.inSave = true;
        this.newMessage.updateRecipients([...this.newMessage.messageRecipients, ...this.newMessage.extraRecipients]);
        this.newMessage.blockedUsers = await this.getBlockedUsers();
        if (this.newMessage.blockedUsers.length > 0) {
          this.$refs.theCommunicationChannelIsBlocked.show();
          this.isLoading = false;
          return;
        }

        this.sendMessage();
        return;
      }

      this.isLoading = false;
      this.handleInvalidForm();
    },
    showMessageRecipientsLimitModal() {
      if (this.hasPermissionToUseGroupsAsDistributionLists) {
        this.showTooManyRecipientsWithPermissionWarning = Vue.filter('fromTextKey')(
          'MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_WITH_PERMISSION'
        );
        this.$refs.tooManyRecipientsWithPermissionModal.show();
      } else {
        this.showTooManyRecipientsWarning = Vue.filter('fromTextKey')('MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_EMPLOYEE');
        this.$refs.tooManyRecipientsModal.show();
      }
    },
    okClickedWhenTooManyRecipients() {
      this.$refs.tooManyRecipientsWithPermissionModal.hide();
      this.newMessage.hasTooManyRecipientsWarningAccepted = true;
      this.handleSendClicked();
    },
    removeBlockedConfirmed() {
      this.$refs.theCommunicationChannelIsBlocked.hide();
      this.newMessage.removeBlockedRecipients();
      this.resetRecipientsInput = true;

      this.$nextTick(() => {
        this.resetRecipientsInput = false;
        this.messageRecipientSet = this.newMessage.messageRecipients;
        this.messageBccRecipientSet = this.newMessage.messageBccRecipients;

        if (this.newMessage.anyRecipients) {
          this.sendMessage();
        } else {
          this.isLoading = false;
          this.inSave = false;
          this.saveDraft();
        }
      });
    },
    async getBlockedUsers() {
      if (this.newMessage.selectedInbox.mailBoxOwnerType !== messageOwnerTypes.INSTITUTION_PROFILE) {
        return [];
      }

      const recipientsPotentiallyBlocked = this.newMessage.getPotentiallyBlockedRecipients(
        this.chosenFolderAndMailOwner
      );
      if (recipientsPotentiallyBlocked.length === 0) {
        return [];
      }

      return await this.getBlockedRecipients(recipientsPotentiallyBlocked);
    },
    async getBlockedRecipients(recipients) {
      const profiles = recipients.filter(profile => !profile.otpInboxId);
      const otpInboxIds = recipients.filter(profile => profile.otpInboxId).map(otp => otp.otpInboxId);
      const creator = this.newMessage.getCreatorForBlockedUsersRequest(this.profile.id, this.institutions);
      const response = await this.resolveBlockedUsers({
        creator: creator,
        profiles: profiles,
        otpInboxIds: otpInboxIds,
      });
      if (response.length === 0) {
        return [];
      }
      const blockedProfiles = response.filter(profile => !profile.otpInbox);
      const blockedOtps = response.filter(profile => profile.otpInbox);
      const results = [...blockedProfiles, ...blockedOtps];
      return [...new Set(results)];
    },

    async sendMessage() {
      const payloadAttachments = await this.prepareAttachmentsPayload();

      for (const chunk of this.newMessage.getRecipientsIterator()) {
        const enrichedRecipients = this.newMessage.enrichRecipients(chunk.recipients, this.chosenFolderAndMailOwner);
        const enrichedBccRecipients = this.newMessage.enrichRecipients(
          chunk.bccRecipients,
          this.chosenFolderAndMailOwner
        );
        let forwardInfo;

        switch (this.newMessage.action) {
          case MessageFormActionEnum.FORWARD:
            forwardInfo = {
              forwardedThreadId: this.newMessage.relatedThreadId,
            };
            break;
          case MessageFormActionEnum.ANSWER_THREAD_CREATOR:
            forwardInfo = {
              forwardedThreadId: this.newMessage.relatedThreadId,
              forwardedMessageIds: [this.newMessage.replyOrForwardMessage.id],
              directReplyToCreator: true,
            };
            break;
          case MessageFormActionEnum.ANSWER_DIRECTLY:
            forwardInfo = {
              forwardedThreadId: this.newMessage.relatedThreadId,
              forwardedMessageIds: [this.newMessage.replyOrForwardMessage.id],
              directReply: true,
            };
            break;
        }

        const payload = {
          recipients: enrichedRecipients,
          bccRecipients: enrichedBccRecipients,
          subject: this.newMessage.messageSubject,
          text: this.newMessage.messageText,
          sensitivityLevel: this.newMessage.isSensitive ? sensitivityLevel.HIGH : sensitivityLevel.MEDIUM,
          attachments: payloadAttachments,
          creator: this.newMessage.getCreatorForPostMessage(this.institutions),
          mailBoxOwnerType: this.newMessage.getMailBoxOwnerTypeForPostMessage(),
          otpInboxId: this.newMessage.selectedOtpInbox ? this.newMessage.selectedOtpInbox : undefined,
          forwardInfo,
        };

        this.startNewThread(payload).then(
          response => this.handleSendMessageResponse(payload.sensitivityLevel, response),
          error => {
            this.isLoading = false;
            if (error.status.subCode === 5) {
              this.$refs.theCommunicationChannelIsBlocked.show();
            }
          }
        );
      }
    },
    cancel() {
      if (this.newMessage.messageText.length === 0) {
        this.discardMessage();
      } else {
        this.$refs.cancelMessageModal.show();
      }
    },
    discardMessage() {
      this.inSave = true;
      this.deleteOldDraft();
      if (this.$route.params.subscriptionId) {
        this.$router.push({
          path: '/beskeder/' + this.$route.params.subscriptionId,
        });
      } else {
        this.$router.push({ path: '/beskeder' });
      }
    },
    checkForExternalRecipient(newRecipients, existingRecipients) {
      const isAddingRecipients = newRecipients.length > existingRecipients.length;

      if (!isAddingRecipients) {
        return;
      }

      const newRecipient = newRecipients[newRecipients.length - 1];
      if (
        this.sameAuthorityInstitutions.some(
          institution => institution.institutionCode === newRecipient.institutionCode
        ) === false ||
        newRecipient.otpInboxId
      ) {
        this.$refs.externalRecipientsToastr.show();
      }
    },
    onSelectRecipients(users) {
      const recipientsAdded = users.length > this.newMessage.messageRecipients.length;
      this.checkForExternalRecipient(users, this.newMessage.messageRecipients);
      this.newMessage.updateRecipients(users);
      this.validateMessageRecipients(recipientsAdded);
      this.setSelectedRecipients(this.newMessage.allRecipients);
      this.validator.validateMessageRecipientsLimits();
      this.saveDraft();
    },
    onSelectBccRecipients(users) {
      this.checkForExternalRecipient(users, this.newMessage.messageBccRecipients);
      this.newMessage.updateBccRecipients(users);
      this.setSelectedRecipients(this.newMessage.allRecipients);
      this.validator.validateMessageRecipientsLimits();
      this.saveDraft();
    },
    handleExtraRecipientsSelected(recipients) {
      const isAdded = recipients.length > this.newMessage.extraRecipients;
      this.validateMessageRecipients(isAdded);
      this.setSelectedRecipients(this.newMessage.allRecipients);
      this.newMessage.updateExtraRecipients(recipients);
      this.validator.validateMessageRecipientsLimits();
      this.saveDraft();
    },
    deleteOldDraft() {
      if (this.selectedDraft.id) {
        this.deleteDraftThread({
          id: this.profile.id,
          key: this.selectedDraft.id,
        });
      }
      if (localStorage.getItem('tempDraftThread') != '' && this.newMessage.selectedInbox) {
        this.deleteDraftThread({
          id: this.profile.id,
          key: localStorage.getItem('tempDraftThread'),
          selectedInbox: this.newMessage.selectedInbox,
        });
      }
    },
    selectedInboxChanged() {
      this.newMessage.setInstitutionCodeFromSelectedInbox(this.commonInboxes, this.institutions);
      this.newMessage.updateRecipients(this.messageRecipients);
      this.saveDraft();
    },
    cancelWarning() {
      this.$refs.autoReplyModal.hide();
    },
    acceptWarning() {
      // todo: seems to be not used any more, there is no place where it is shown
      this.$refs.autoReplyModal.hide();
      // this.prepareAndSendMessage(this.message.messageRecipients);
    },
    filesChanged(attachments) {
      this.newMessage.setAttachments(attachments);
    },
    saveDraft() {
      if (!this.draftShouldBeSaved()) {
        return;
      }
      this.saveDraftThread(this.getDraftThreadData());
    },
    mapSelectedDraft() {
      if (!isEmpty(this.selectedDraft)) {
        this.newMessage.updateFromDraft(this.selectedDraft, this.messageIdFromPath, this.subscriptionIdFromPath);
        this.messageRecipientSet = this.selectedDraft.messageRecipients;
        this.messageBccRecipientSet = this.selectedDraft.bccRecipients;
        this.existingExtraRecipients = this.selectedDraft.extraRecipients;
        this.isBccFieldOpen = this.selectedDraft.bccRecipients?.length > 0;
        this.newMessage.action = this.selectedDraft.action;
        this.newMessage.relatedThreadId = this.selectedDraft.relatedThreadId;
        this.newMessage.institutionCode = this.selectedDraft.institutionCode;
      }
      this.setSelectedRecipients(this.newMessage.allRecipients);
    },
    initSelectedInbox() {
      this.getSameAuthorityInstitutions(this.filteredInstitutionOptions);

      this.chosenFolderAndMailOwner.mailBoxOwnerType = this.newMessage.initializeSelectedInboxAndInstitutionCode(
        this.chosenFolderAndMailOwner,
        this.filteredInstitutionOptions,
        this.activeInstitutionCodes,
        this.commonInboxes
      );
    },
    handleInvalidForm() {
      if (this.validator.showMessageRecipientsLimitWarning) {
        this.showMessageRecipientsLimitModal();
      }
      if (!this.validator.validSecureFileRecipients) {
        this.$refs.shareableSecureFileModal.show();
      }
    },
    updateExistingMedia(existingMedia) {
      if (existingMedia.length > 0) {
        const updatedMedia = existingMedia.filter(att => att.media != null && att.media.updated);
        if (updatedMedia.length > 0) {
          this.updateAttachments({
            media: Vue.filter('prepareMedia')(updatedMedia),
          });
        }
      }
    },
    draftShouldBeSaved() {
      if (this.inSave) {
        return false;
      }
      if (this.selectedDraft != null && this.selectedDraft.isRemoved) {
        this.resetSelectedDraftThread();
        return false;
      }
      return !this.validator.getIsEmptyMessage();
    },
    getDraftThreadData() {
      return this.newMessage.getDraftThreadData(
        this.selectedDraft.id,
        this.oldDraftThreadId,
        this.institutionCodesFromMailBox[0]
      );
    },
    async prepareAttachmentsPayload() {
      const messageAttachments = this.newMessage.prepareAttachments(this.institutions);
      this.updateExistingMedia(messageAttachments.existingMedia);
      const existingMedia = messageAttachments.existingMedia.map(att => parseInt(att.id));

      let payloadAttachments = [];
      if (someNewAttachements()) {
        try {
          const securePayloadAttachments = await this.createSecureDocumentsLinks(
            messageAttachments.attachedSecureDocumentIds,
            messageAttachments.ownerInstitutionProfileId
          );
          payloadAttachments = [...payloadAttachments, ...securePayloadAttachments];
          messageAttachments.attachedSecureDocumentIds = [];
          const createdDocuments = await this.createDocuments(messageAttachments);
          payloadAttachments = [...payloadAttachments, ...createdDocuments, ...existingMedia];
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        payloadAttachments = existingMedia;
      }
      return payloadAttachments;

      function someNewAttachements() {
        return (
          messageAttachments.media != null ||
          messageAttachments.files != null ||
          messageAttachments.links != null ||
          messageAttachments.attachedSecureDocumentIds != null
        );
      }
    },
    handleSendMessageResponse(sensitivityLevel, response) {
      if (response.data.status.code === 0) {
        this.$parent.clearSearch();
        this.isLoading = false;
        const canAccessSensitiveMessage = sensitivityLevel !== sensitivityLevel.HIGH || this.isSteppedUp;

        if (this.newMessage.selectedOtpInbox) {
          this.chosenFolderAndMailOwner.mailOwnerId = this.newMessage.selectedOtpInbox;
          this.chosenFolderAndMailOwner.mailOwnerType = messageOwnerTypes.OTP_INBOX;
        }

        const isSendingFromDifferentInboxes = this.newMessage.selectedInboxDifferentFromChosenFolder(
          this.chosenFolderAndMailOwner
        );
        const isSendingFromActiveInstitution = this.newMessage.isSendingFromActiveInstitution(
          this.activeInstitutionCodes,
          this.children,
          this.activeChildIds
        );

        const isRedirectingToMessage =
          response.data.data &&
          canAccessSensitiveMessage &&
          !isSendingFromDifferentInboxes &&
          isSendingFromActiveInstitution;

        if (isRedirectingToMessage) {
          // Change view to new subscription
          const subscriptionId = response.data.data.data;

          this.$router.push({
            name: 'uniqueThread',
            params: { id: subscriptionId },
            query: {
              otpInboxId: this.chosenFolderAndMailOwner.otpInboxId
                ? this.chosenFolderAndMailOwner.otpInboxId
                : undefined,
            },
          });

          this.$emit('messageSent');

          this.$nextTick(() => {
            this.$forceUpdate();
          });
        } else {
          this.$router.push({ path: '/beskeder' });
          this.$nextTick(() => {
            this.$forceUpdate();
          });
        }
        this.isLoading = false;

        this.deleteOldDraft();
      } else if (response.data.statusCode === 1) {
        // Backend could not decide the sending mailBoxOwner
        this.setSelectableMailBoxOwners(response.data.data.possibleSenders);
        this.newMessage.showSenderInput = true;
        this.isLoading = false;
      } else if (response.data.status.code === 2) {
        this.isLoading = false;
        this.$refs.unknownEmailsToastr.show();
      }
      this.resetAdvancedSearch();
    },
    validateMessageRecipients(recipientsAdded) {
      if (this.validator.validateMessageRecipientsLimitsWithProfile()) {
        return;
      }
      if (recipientsAdded && !this.validator.validateSecureFileRecipients()) {
        this.$refs.shareableSecureFileModal.show();
      }
      if (this.showMessageRecipientsLimitWarningForEmployee) {
        this.showTooManyRecipientsWarning = Vue.filter('fromTextKey')('MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY_EMPLOYEE');
        this.$refs.tooManyRecipientsModal.show();
        return;
      }
      if (this.showMessageRecipientsLimitWarningForNonEmployee) {
        this.showTooManyRecipientsWarning = Vue.filter('fromTextKey')('MESSAGE_VALIDATE_RECIPIENTS_TOO_MANY');
        this.$refs.tooManyRecipientsModal.show();
      }
    },
    async createSecureDocumentsLinks(attachedSecureDocumentIds, ownerInstitutionProfileId) {
      if (!attachedSecureDocumentIds || attachedSecureDocumentIds.length === 0) {
        return [];
      }
      const createLinksPayload = {
        documentIds: attachedSecureDocumentIds,
        ownerInstitutionProfileId: ownerInstitutionProfileId,
      };
      const attachments = await this.createDocumentLinks(createLinksPayload);
      return attachments.map(att => att.id);
    },
    async createDocuments(messageAttachments) {
      const createAttachmentsRsp = await this.createAttachments(messageAttachments);
      const attachments = [
        ...createAttachmentsRsp.files,
        ...createAttachmentsRsp.links,
        ...createAttachmentsRsp.media,
        ...createAttachmentsRsp.documents,
      ];
      return attachments.map(att => att.id);
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.message-icon {
  font-size: 24px;
  padding-right: 5px;
}

.label {
  cursor: pointer;
}

a {
  font-size: 12px;
  color: $color-help-text;
}

#message-new {
  padding: 0 40px 20px 40px;
  width: 100%;
  align-self: center;
  overflow: auto;
  flex-grow: 1;

  @include breakpoint-lg-down {
    padding: 0 20px;
    width: unset;
    align-self: stretch;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  label {
    margin-top: 20px;
    padding: 3px;
  }

  @include breakpoint-lg {
    /deep/ .mce-edit-area iframe {
      min-height: 200px;
    }
  }
}

.cancel-message-link {
  margin-right: 20px;
  font-size: 14px;
  line-height: 36px;
  cursor: pointer;
}

.send-btn {
  width: 137px;
  height: 50px;
}

.btn-light,
.btn-light:not([disabled]):not(.disabled) {
  &:hover,
  &:active {
    color: $color-grey;
    background-color: transparent;
    border: none;
  }
}

.new-message-heading {
  padding-top: 20px;
  font-size: x-large;
  font-weight: bold;
}

.recipient-input {
  border: 1px solid $color-white;
  padding: 12px 18px;
}

.inline-checkbox {
  display: inline-block;
}

.replied-recipient {
  --input-border-color: var(--color-grey-darker);
}

.message-body {
  padding: 18px;
  border-radius: 8px;
  border: 1px solid var(--color-grey-darker);
  font-size: 15px;
  word-break: break-all;
  position: relative;
  background-color: var(--color-grey-light);
}

/deep/ .custom-checkbox {
  margin-right: 0;
  margin-top: 0 !important;

  .custom-control-description {
    margin-left: 26px;
    font-weight: normal;

    i {
      margin-left: 11px;
    }
  }
  input:checked ~ .custom-control-description i {
    color: $color-alert;
  }
}

.sensitive-msg-container {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.message-utility-container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  min-height: 25px;
}
</style>
